import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaArrowLeft, FaThumbsUp, FaThumbsDown, FaSearch, FaSave, FaArrowRight, FaCircle, FaUsers, FaFileInvoice, FaEye, FaPlaneDeparture, FaPlaneArrival, FaTable, FaEdit } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { dataMysql, buscarCep, data, extract, dataHoraMysql, hora } from '../../../util/util';
import Data from '../../../components/Data';
import ReactSelect from '../../../components/ReactSelect';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import IconButton from '../../../components/IconButton';
import moment from 'moment';
import { RiMailSendLine } from 'react-icons/ri';
import FormButton from '../../../components/FormButton';
import Alert from '../../../components/Alert';
import NavItem from '../../../components/NavItem';
import ModalButton from '../../../components/ModalButton';

export default function CadastroEventoParticipanteOperador({ match }) {
    const id = match.params.id;
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    const [tableSelect, setTableSelect] = useState({});
    const [filtro, setFiltro] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const [selectEventos, setSelectEventos] = useState({
        eventoSelecionado: null,
        listaEventos: [
            {
                value: null,
                label: 'Buscando...'
            }
        ]
    });
    const [dados, setDados] = useState({
        posicao: 0,
        participante: {
            nome: 'buscando...',
        }
    });
    const [dadosTotais, setDadosTotais] = useState([]);
    const funcaoDados = () => dados;
    const funcaoFiltro = () => filtro;
    const [dadosModalHistorico, setDadosModalHistorico] = useState({});
    const [historico, setHistorico] = useState([]);
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const idCliente = params.get('idCliente');
    const nomeCliente = params.get('nomeCliente');

    const preencherCep = (cep, dadosAtuais) => {
        buscarCep(cep).then((resposta) => {
            if (!resposta.erro) {
                dadosAtuais.participante.enderecobairro = resposta.bairro;
                dadosAtuais.participante.enderecocomplemento = resposta.complemento;
                dadosAtuais.participante.enderecologradouro = resposta.logradouro;
                dadosAtuais.participante.enderecopais = "30";
                dadosAtuais.participante.nomeEnderecopais = "Brasil";
                dadosAtuais.participante.enderecomunicipio = resposta.ibge;
                dadosAtuais.participante.nomeEnderecomunicipio = resposta.localidade;
                setDados({ ...dadosAtuais });
            }
        });
    }
    const buscarHistorico = (idParticipante) => {
        api({
            method: "get",
            action: '/participanteHistoricoSituacao',
            token: token,
            params: { participante: idParticipante }
        }).then(response => {
            var dadosRecebidosHistorico = response.data.data;
            setHistorico(dadosRecebidosHistorico);
        }).catch(err => {
            //console.log(err)
        });
    };

    const buscar = (tipo = 'posicao') => {
        var eventoFiltro = selectEventos.eventoSelecionado ? selectEventos.eventoSelecionado : null;
        if (["cliente"].includes(tipo)) {
            eventoFiltro = null;
        }
        api({
            method: "get",
            action: '/buscarParticipanteOperador/' + usuario,
            token: token,
            params: {
                cliente: funcaoFiltro().cliente ?? null,
                posicao: ["posicao", "cliente"].includes(tipo) ? dados.posicao : null,
                eventoSelecionado: eventoFiltro,
                evento: tipo === 'evento' ? selectEventos.eventoSelecionado : null,
                situacao: funcaoFiltro().situacao ?? null,
                situacaocartaconvite: funcaoFiltro().situacaocartaconvite ?? null,
                situacaocartainformativa: funcaoFiltro().situacaocartainformativa ?? null,
            }
        }).then(response => {
            if (response.data.data.participante) {
                //dados do participante
                var dadosParticipante = extract({
                    jsonLaravel: response.data.data.participante,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'tipoparticipante',
                    ]
                });
                dados.participante = dadosParticipante;
                dados.participante.hoteldatacheckin = data(dadosParticipante.hoteldatacheckin);
                dados.participante.hoteldatacheckout = data(dadosParticipante.hoteldatacheckout);
                dados.participante.datanascimento = data(dadosParticipante.datanascimento);
                dados.participante.dataopcaoaereo = data(dadosParticipante.dataopcaoaereo);
                dados.participante.horacartainformativa = hora(dadosParticipante.datacartainformativa);
                dados.participante.datacartainformativa = data(dadosParticipante.datacartainformativa);
                dados.participante.dataconfirmacaocartainformativa = data(dadosParticipante.dataconfirmacaocartainformativa);
                dados.participante.horacartaconvite = hora(dadosParticipante.datacartaconvite);
                dados.participante.datacartaconvite = data(dadosParticipante.datacartaconvite);
                dados.participante.datacartaassinada = data(dadosParticipante.datacartaassinada);
                dados.participante.dataconfirmacaodados = data(dadosParticipante.dataconfirmacaodados);
                dados.participante.horaescolhaaereo = hora(dadosParticipante.dataescolhaaereo);
                dados.participante.dataescolhaaereo = data(dadosParticipante.dataescolhaaereo);
                dados.participante.datainscricao = data(dadosParticipante.datainscricao);
                dados.posicao = response.data.data.posicaoAtual;

                setDados({ ...dados });

                //atualiza lista de historico do participante
                buscarHistorico(dadosParticipante.id);

                //atualiza lista de eventos a fazer
                selectEventos.eventoSelecionado = response.data.data.eventoAtual;
                selectEventos.listaEventos = response.data.data.listaEventos;
                setSelectEventos(selectEventos);

                //atualiza dados de Totais
                setDadosTotais(response.data.data.totais);
            } else {// nao achou
                dados.participante = null;
                setDados({ ...dados });

                //atualiza dados de Totais
                setDadosTotais(response.data.data.totais);
            }
        }).catch(err => {
            //console.log(err)
        });
    };// buscar

    const salvarDadosParticipante = () => {
        let dadosParticipanteSalvar = { ...dados.participante }; //faz alterações antes de enviar para salvar
        if (dadosParticipanteSalvar.hoteldatacheckin) {
            dadosParticipanteSalvar.hoteldatacheckin = dataMysql(dadosParticipanteSalvar.hoteldatacheckin);
        }
        if (dadosParticipanteSalvar.hoteldatacheckout) {
            dadosParticipanteSalvar.hoteldatacheckout = dataMysql(dadosParticipanteSalvar.hoteldatacheckout);
        }
        if (dadosParticipanteSalvar.datanascimento) {
            dadosParticipanteSalvar.datanascimento = dataMysql(dadosParticipanteSalvar.datanascimento);
        }
        if (dadosParticipanteSalvar.dataopcaoaereo) {
            dadosParticipanteSalvar.dataopcaoaereo = dataMysql(dadosParticipanteSalvar.dataopcaoaereo);
        }
        if (dadosParticipanteSalvar.datacartainformativa) {
            dadosParticipanteSalvar.datacartainformativa = dataHoraMysql(dadosParticipanteSalvar.datacartainformativa + ' ' + dadosParticipanteSalvar.horacartainformativa);
        }
        if (dadosParticipanteSalvar.dataconfirmacaocartainformativa) {
            dadosParticipanteSalvar.dataconfirmacaocartainformativa = dataMysql(dadosParticipanteSalvar.dataconfirmacaocartainformativa);
        }
        if (dadosParticipanteSalvar.datacartaconvite) {
            dadosParticipanteSalvar.datacartaconvite = dataHoraMysql(dadosParticipanteSalvar.datacartaconvite + ' ' + dadosParticipanteSalvar.horacartaconvite);
        }
        if (dadosParticipanteSalvar.datacartaassinada) {
            dadosParticipanteSalvar.datacartaassinada = dataMysql(dadosParticipanteSalvar.datacartaassinada);
        }
        if (dadosParticipanteSalvar.dataconfirmacaodados) {
            dadosParticipanteSalvar.dataconfirmacaodados = dataMysql(dadosParticipanteSalvar.dataconfirmacaodados);
        }
        if (dadosParticipanteSalvar.dataescolhaaereo) {
            dadosParticipanteSalvar.dataescolhaaereo = dataHoraMysql(dadosParticipanteSalvar.dataescolhaaereo + ' ' + dadosParticipanteSalvar.horaescolhaaereo);
        }
        if (dadosParticipanteSalvar.datainscricao) {
            dadosParticipanteSalvar.datainscricao = dataMysql(dadosParticipanteSalvar.datainscricao);
        }

        //bugfix vem como array (remove pra nao alterar)
        delete dadosParticipanteSalvar.situacaoevento;
        delete dadosParticipanteSalvar.evento;

        api({
            method: 'put',
            action: '/participante/' + dadosParticipanteSalvar.id,
            token: token,
            data: dadosParticipanteSalvar,
        })
            .then(function (response) {
                toast.success('Dados registrados com sucesso!');
            })
            .catch(function (error) {
                //alert(error)
            });
    };//salvarDadosParticipante

    useEffect(() => { //onload
        if (idCliente) {
            filtro.cliente = idCliente;
            filtro.nomeCliente = nomeCliente;
            setFiltro({ ...filtro });
            setTimeout(() => {
                buscar('cliente');
            }, 500);
        } else {
            buscar();
        }
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {dados.participante?.evento?.id ?
                        <NavItem
                            target="_blank"
                            to={{
                                pathname: "/eventoRelatorioParticipante/" + dados.participante?.evento?.id,
                            }}
                            icon={<FaTable size={20} />}
                            color="outline-dark">
                            Relatorio de Participantes
                        </NavItem>
                        : null
                    }
                </ul>
            </div>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Buscar Participante</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group">
                                                <label>Participante</label>
                                                <ReactSelect
                                                    id="cliente"
                                                    value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                    onChange={(e) => {
                                                        filtro.cliente = e.value;
                                                        filtro.nomeCliente = e.label;
                                                        setFiltro({ ...filtro });
                                                        setTimeout(() => {
                                                            buscar('cliente');
                                                        }, 500);
                                                    }}
                                                    loadOptions={(input) => {
                                                        return api({
                                                            method: 'get',
                                                            action: '/participante',
                                                            token: token,
                                                            params: {
                                                                nome: input,
                                                                tipoCadastro: "participante",
                                                                situacaoRegistro: "ativo",
                                                                limit: 30,
                                                            }
                                                        })
                                                            .then(response => {
                                                                let options = response.data.data.map((registro) => {
                                                                    var label = registro.codigo +
                                                                        ' - ' + registro.nome +
                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '') +
                                                                        (registro.evento ? ' | ' + registro.evento.nome : '');
                                                                    return {
                                                                        value: registro.id,
                                                                        label: label
                                                                    };
                                                                });
                                                                return options;
                                                            })
                                                            .catch(function (error) {
                                                                //console.log(error);
                                                            });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Evento</label>
                                                    <ReactSelect
                                                        value={{ value: selectEventos.eventoSelecionado }}
                                                        onChange={(e) => {
                                                            //limpa select cliente
                                                            filtro.cliente = null;
                                                            filtro.nomeCliente = null;

                                                            setFiltro({ ...filtro });

                                                            //seta select evento
                                                            selectEventos.eventoSelecionado = e.value;

                                                            setSelectEventos({ ...selectEventos });

                                                            setTimeout(() => {
                                                                buscar('evento');
                                                            }, 500);
                                                        }}
                                                        options={selectEventos.listaEventos}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <ReactSelect
                                                        id="situacao"
                                                        value={{ value: filtro.situacao, label: filtro.nomeSituacao }}
                                                        onChange={(e) => {
                                                            //limpa select cliente
                                                            filtro.cliente = null;
                                                            filtro.nomeCliente = null;

                                                            filtro.situacao = e.value;
                                                            filtro.nomeSituacao = e.label;

                                                            setFiltro({ ...filtro });

                                                            setTimeout(() => {
                                                                buscar('evento');
                                                            }, 500);
                                                        }}
                                                        loadOptions={(input) => {
                                                            return api({
                                                                method: 'get',
                                                                action: '/eventoParticipanteSituacao',
                                                                token: token,
                                                                params: {
                                                                    situacaoRegistro: "ativo",
                                                                    nome: input
                                                                }
                                                            })
                                                                .then(response => {
                                                                    let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                    return [
                                                                        { value: null, label: 'Todos' },
                                                                        ...options
                                                                    ];
                                                                })
                                                                .catch(function (error) {
                                                                    //console.log(error);
                                                                });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Carta Convite</label>
                                                    <ReactSelect
                                                        value={{ value: filtro.situacaocartaconvite, label: filtro.nomeSituacaocartaconvite }}
                                                        onChange={(e) => {
                                                            //limpa select cliente
                                                            filtro.cliente = null;
                                                            filtro.nomeCliente = null;

                                                            filtro.situacaocartaconvite = e.value;
                                                            filtro.nomeSituacaocartaconvite = e.label;

                                                            setFiltro({ ...filtro });

                                                            setTimeout(() => {
                                                                buscar('evento');
                                                            }, 500);
                                                        }}
                                                        options={[
                                                            { value: '', label: 'Todas' },
                                                            { value: 'confirmada', label: 'Confirmada' },
                                                            { value: 'naoconfirmada', label: 'Não Confirmada' },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Carta Informativa</label>
                                                    <ReactSelect
                                                        value={{ value: filtro.situacaocartainformativa, label: filtro.nomeSituacaocartainformativa }}
                                                        onChange={(e) => {
                                                            //limpa select cliente
                                                            filtro.cliente = null;
                                                            filtro.nomeCliente = null;

                                                            filtro.situacaocartainformativa = e.value;
                                                            filtro.nomeSituacaocartainformativa = e.label;

                                                            setFiltro({ ...filtro });

                                                            setTimeout(() => {
                                                                buscar('evento');
                                                            }, 500);
                                                        }}
                                                        options={[
                                                            { value: '', label: 'Todas' },
                                                            { value: 'confirmada', label: 'Confirmada' },
                                                            { value: 'naoconfirmada', label: 'Não Confirmada' },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="page-title">
                    <h3>Cliente: <strong>{dados.participante?.evento?.cliente?.nome}</strong></h3>
                    <h3>Evento: <strong>{dados.participante?.evento?.nome}</strong></h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            {dadosTotais.map((elemento) => {
                                                return <div className="col-sm-2">
                                                    <label htmlFor="floatingInput"><FaCircle size={15} style={{ color: elemento.cor }} /> {elemento.titulo}: {elemento.quantidade}</label>
                                                </div>;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row" style={{ marginBottom: "10px" }}>
                    <div className="col-sm-4 d-flex">
                        {dados.posicao > 0 ?
                            <IconButton
                                small={true}
                                onClick={() => {
                                    dados.posicao = dados.posicao - 1;
                                    setDados({ ...dados });
                                    setTimeout(() => {
                                        buscar('posicao');
                                    }, 500);
                                }}
                                icon={<FaArrowLeft size={20} />}
                                color="primary">Anterior</IconButton>
                            : null
                        }
                        <div style={{ width: "10px" }}></div>
                        <IconButton
                            small={true}
                            onClick={() => {
                                dados.posicao = dados.posicao + 1;
                                setDados({ ...dados });
                                setTimeout(() => {
                                    buscar('posicao');
                                }, 500);
                            }}
                            icon={<FaArrowRight size={20} />}
                            color="primary">Próximo</IconButton>
                        <div style={{ width: "10px" }}></div>
                        <IconButton
                            small={true}
                            onClick={() => {
                                history.push({
                                    pathname: "/eventoParticipante/" + dados.participante?.evento.id,
                                    state: { operador: usuario }
                                });
                            }}
                            icon={<FaUsers size={20} />}
                            color="primary">Lista de Participantes</IconButton>
                    </div>
                    <div style={{ marginBottom: "10px" }} className="col-sm-8 d-flex justify-content-end">
                        <FormButton
                            small={true}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                            icon={<FaEye size={20} />}
                            color="primary"
                            data={{
                                token: token,
                                tipoCarta: "modelocartaconvite",
                                idParticipantes: dados.participante?.id ?? null//exemplo: "234523452345,324532453,23452345"
                            }}
                        >Visualizar Convite</FormButton>
                        <div style={{ width: "5px" }}></div>
                        <IconButton
                            small={true}
                            icon={<RiMailSendLine size={20} />}
                            onClick={() => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Convite?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaConvite',
                                                token: token,
                                                data: {
                                                    idParticipantes: dados.participante?.id ?? null
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                            }
                            color="primary"> Enviar Convite</IconButton>
                        <div style={{ width: "20px" }}></div>
                        <FormButton
                            small={true}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                            icon={<FaEye size={20} />}
                            color="primary"
                            data={{
                                token: localStorage.getItem('token'),
                                tipoCarta: "modelocarta",
                                idParticipantes: dados.participante?.id ?? null //exemplo: "234523452345"
                            }}
                        >Visualizar Informativa</FormButton>
                        <div style={{ width: "5px" }}></div>
                        <IconButton
                            small={true}
                            icon={<RiMailSendLine size={20} />}
                            onClick={() => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Informativa?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaInformativa',
                                                token: token,
                                                data: {
                                                    idParticipantes: dados.participante?.id ?? null,
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                            }
                            color="primary"> Enviar Informativa</IconButton>
                    </div>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { salvarDadosParticipante(); e.preventDefault(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-4 d-flex">
                                                    <h4>Dados do Participante <span className="badge" style={{ backgroundColor: dados.participante?.situacaoevento?.cor || null }} >{dados.participante?.situacaoevento?.nome || null}</span></h4>
                                                </div>
                                                <div className="col-sm-8 d-flex justify-content-end">
                                                    {dados.participante?.datacartaconvite ?
                                                        <span title={"Carta convite enviada em: " + (dados.participante?.datacartaconvite ?? "") + ' ' + (dados.participante?.horacartaconvite ?? "")} className="badge bg-info">Carta convite enviada em: {(dados.participante?.datacartaconvite ?? "") + ' ' + (dados.participante?.horacartaconvite ?? "")}</span>
                                                        : null}
                                                    {dados.participante?.datacartaassinada ?
                                                        <span title={"Carta convite confirmada em: " + (dados.participante?.datacartaassinada ?? "")} className="badge bg-success">Carta convite confirmada em: {(dados.participante?.datacartaassinada ?? "")}</span>
                                                        : null}
                                                    {dados.participante?.datacartainformativa ?
                                                        <span title={"Carta informativa enviada em: " + (dados.participante?.datacartainformativa ?? "") + ' ' + (dados.participante?.horacartainformativa ?? "")} className="badge bg-info">Carta informativa enviada em: {(dados.participante?.datacartainformativa ?? "") + ' ' + (dados.participante?.horacartainformativa ?? "")}</span>
                                                        : null}
                                                    {dados.participante?.dataconfirmacaocartainformativa ?
                                                        <span title={"Carta informativa confirmada em: " + (dados.participante?.dataconfirmacaocartainformativa ?? "")} className="badge bg-success">Carta informativa confirmada em: {(dados.participante?.dataconfirmacaocartainformativa ?? "")}</span>
                                                        : null}
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome Completo"
                                                            value={dados.participante?.nome ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.nome = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Completo</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="contato" placeholder="Nome para o Crachá"
                                                            value={dados.participante?.contato ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.contato = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Nome para o Crachá</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                            value={dados.participante?.email ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.email = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">E-mail</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="emailsecundario" placeholder="Outros E-mail's"
                                                            value={dados.participante?.emailsecundario ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.emailsecundario = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Outros E-mail's</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="crm" placeholder="CRM"
                                                            value={dados.participante?.crm ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.crm = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">CRM</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Estado do CRM</label>
                                                        <ReactSelect
                                                            id={"crmuf"}
                                                            value={{ value: dados.participante?.crmuf ?? "", label: dados.participante?.crmuf }}
                                                            onChange={(e) => {
                                                                dados.participante.crmuf = e.value;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: "AC", label: "AC" },
                                                                { value: "AL", label: "AL" },
                                                                { value: "AP", label: "AP" },
                                                                { value: "AM", label: "AM" },
                                                                { value: "BA", label: "BA" },
                                                                { value: "CE", label: "CE" },
                                                                { value: "DF", label: "DF" },
                                                                { value: "ES", label: "ES" },
                                                                { value: "GO", label: "GO" },
                                                                { value: "MA", label: "MA" },
                                                                { value: "MT", label: "MT" },
                                                                { value: "MS", label: "MS" },
                                                                { value: "MG", label: "MG" },
                                                                { value: "PA", label: "PA" },
                                                                { value: "PB", label: "PB" },
                                                                { value: "PR", label: "PR" },
                                                                { value: "PE", label: "PE" },
                                                                { value: "PI", label: "PI" },
                                                                { value: "RJ", label: "RJ" },
                                                                { value: "RN", label: "RN" },
                                                                { value: "RS", label: "RS" },
                                                                { value: "RO", label: "RO" },
                                                                { value: "RR", label: "RR" },
                                                                { value: "SC", label: "SC" },
                                                                { value: "SP", label: "SP" },
                                                                { value: "SE", label: "SE" },
                                                                { value: "TO", label: "TO" },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="rg" placeholder="RG"
                                                            value={dados.participante?.rg ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.rg = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">RG</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <InputMask mask="999.999.999-99" className="form-control form-control-lg" id="cpf" placeholder="CPF"
                                                            value={dados.participante?.cpf ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.cpf = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">CPF</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <Data
                                                        id={"datanascimento"}
                                                        label="Data de Nascimento"
                                                        value={dados.participante?.datanascimento ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.datanascimento = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="telefone" placeholder="Telefones"
                                                            value={dados.participante?.telefone ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.telefone = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Telefone</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="telefone2" placeholder="Telefone 2"
                                                            value={dados.participante?.telefone2 ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.telefone2 = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Telefone 2</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="celular" placeholder="Celular"
                                                            value={dados.participante?.celular ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.celular = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Celular</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="gerentedistrital" placeholder="Gerente Distrital"
                                                            value={dados.participante?.gerentedistrital ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.gerentedistrital = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Gerente Distrital</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="gerentedistritalemail" placeholder="E-mail Gerente Distrital"
                                                            value={dados.participante?.gerentedistritalemail ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.gerentedistritalemail = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">E-mail Gerente Distrital</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="representante" placeholder="Representante"
                                                            value={dados.participante?.representante ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.representante = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Representante</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="representanteemail" placeholder="E-mail Representante"
                                                            value={dados.participante?.representanteemail ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.representanteemail = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">E-mail Representante</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="especialidade" placeholder="Especialidade"
                                                            value={dados.participante?.especialidade ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.especialidade = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Especialidade</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="instituicao" placeholder="Instituição"
                                                            value={dados.participante?.instituicao ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.instituicao = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Instituição</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Tipo do Participante</label>

                                                        <ReactSelect
                                                            id="tipoparticipante"
                                                            value={{ value: dados.participante?.tipoparticipante ?? "", label: dados.participante?.nomeTipoparticipante }}
                                                            onChange={(e) => {
                                                                dados.participante.tipoparticipante = e.value;
                                                                dados.participante.nomeTipoparticipante = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participanteTipo',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="idconvidado" placeholder="ID"
                                                            value={dados.participante?.idconvidado ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.idconvidado = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">ID</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="socio" placeholder="Sócio"
                                                            value={dados.participante?.socio ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.socio = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Sócio</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Data
                                                                id={"datacartaconvite"}
                                                                label="Data Carta Convite"
                                                                value={dados.participante?.datacartaconvite ?? null}
                                                                onChange={(date) => {
                                                                    dados.participante.datacartaconvite = date;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <InputMask
                                                                    mask="99:99"
                                                                    className="form-control form-control-lg campoHora"
                                                                    id="horacartaconvite"
                                                                    placeholder="Hora Carta Convite"
                                                                    value={dados.participante?.horacartaconvite ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.horacartaconvite = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Carta Convite</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        id={"datacartaassinada"}
                                                        label="Data Aceite Convite"
                                                        value={dados.participante?.datacartaassinada ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.datacartaassinada = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        id={"dataopcaoaereo"}
                                                        label="Data Opção de Aéreo"
                                                        value={dados.participante?.dataopcaoaereo ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.dataopcaoaereo = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        id={"dataconfirmacaodados"}
                                                        label="Data Confirmação Dados"
                                                        value={dados.participante?.dataconfirmacaodados ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.dataconfirmacaodados = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Data
                                                                id={"dataescolhaaereo"}
                                                                label="Data Escolha Aéreo"
                                                                value={dados.participante?.dataescolhaaereo ?? null}
                                                                onChange={(date) => {
                                                                    dados.participante.dataescolhaaereo = date;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <InputMask
                                                                    mask="99:99"
                                                                    className="form-control form-control-lg campoHora"
                                                                    id="horaescolhaaereo"
                                                                    placeholder="Hora Escolha Aéreo"
                                                                    value={dados.participante?.horaescolhaaereo ?? null}
                                                                    onChange={(e) => {
                                                                        dados.participante.horaescolhaaereo = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Escolha Aéreo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        id={"datainscricao"}
                                                        label="Data Inscrição"
                                                        value={dados.participante?.datainscricao ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.datainscricao = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Data
                                                                id={"datacartainformativa"}
                                                                label="Data Carta Informativa"
                                                                value={dados.participante?.datacartainformativa ?? null}
                                                                onChange={(date) => {
                                                                    dados.participante.datacartainformativa = date;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <InputMask
                                                                    mask="99:99"
                                                                    className="form-control form-control-lg campoHora"
                                                                    id="horacartainformativa"
                                                                    placeholder="Hora Carta Informativa"
                                                                    value={dados.participante?.horacartainformativa ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.horacartainformativa = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Carta Informativa</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        id={"dataconfirmacaocartainformativa"}
                                                        label="Data Confirmação Informativa"
                                                        value={dados.participante?.dataconfirmacaocartainformativa ?? null}
                                                        onChange={(date) => {
                                                            dados.participante.dataconfirmacaocartainformativa = date;
                                                            setDados({ ...dados });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <h4>Dados de Endereço</h4>
                                                <div className="col-sm-8">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="enderecologradouro" placeholder="Rua / Logradouro"
                                                            value={dados.participante?.enderecologradouro ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.enderecologradouro = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Rua / Logradouro</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="endereconumero" placeholder="Número"
                                                            value={dados.participante?.endereconumero ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.endereconumero = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Número</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="enderecocomplemento" placeholder="Complemento"
                                                            value={dados.participante?.enderecocomplemento ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.enderecocomplemento = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Complemento</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="enderecocep" placeholder="CEP"
                                                            value={dados.participante?.enderecocep ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.enderecocep = e.target.value;
                                                                preencherCep(e.target.value, dados);
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">CEP</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="enderecobairro" placeholder="Bairro"
                                                            value={dados.participante?.enderecobairro ?? ""}
                                                            onChange={(e) => {
                                                                dados.participante.enderecobairro = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Bairro</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Estado</label>
                                                        <ReactSelect
                                                            id={"enderecouf"}
                                                            value={{ value: dados.participante?.enderecouf ?? "", label: dados.participante?.enderecouf }}
                                                            onChange={(e) => {
                                                                dados.participante.enderecouf = e.value;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: "AC", label: "AC" },
                                                                { value: "AL", label: "AL" },
                                                                { value: "AP", label: "AP" },
                                                                { value: "AM", label: "AM" },
                                                                { value: "BA", label: "BA" },
                                                                { value: "CE", label: "CE" },
                                                                { value: "DF", label: "DF" },
                                                                { value: "ES", label: "ES" },
                                                                { value: "GO", label: "GO" },
                                                                { value: "MA", label: "MA" },
                                                                { value: "MT", label: "MT" },
                                                                { value: "MS", label: "MS" },
                                                                { value: "MG", label: "MG" },
                                                                { value: "PA", label: "PA" },
                                                                { value: "PB", label: "PB" },
                                                                { value: "PR", label: "PR" },
                                                                { value: "PE", label: "PE" },
                                                                { value: "PI", label: "PI" },
                                                                { value: "RJ", label: "RJ" },
                                                                { value: "RN", label: "RN" },
                                                                { value: "RS", label: "RS" },
                                                                { value: "RO", label: "RO" },
                                                                { value: "RR", label: "RR" },
                                                                { value: "SC", label: "SC" },
                                                                { value: "SP", label: "SP" },
                                                                { value: "SE", label: "SE" },
                                                                { value: "TO", label: "TO" },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Cidade</label>
                                                        <ReactSelect
                                                            value={{ value: dados.participante?.enderecomunicipio ?? "", label: dados.participante?.nomeEnderecomunicipio }}
                                                            onChange={(e) => {
                                                                dados.participante.enderecomunicipio = e.value;
                                                                dados.participante.nomeEnderecomunicipio = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/municipio',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        uf: dados.participante?.enderecouf ?? null
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>País</label>
                                                        <ReactSelect
                                                            value={{ value: dados.participante?.enderecopais ?? "", label: dados.participante?.nomeEnderecopais }}
                                                            onChange={(e) => {
                                                                dados.participante.enderecopais = e.value;
                                                                dados.participante.nomeEnderecopais = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/pais',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aeroportoOrigem" placeholder="Aeroporto de Origem"
                                                            value={dados.participante?.aeroportoOrigem}
                                                            onChange={(e) => {
                                                                dados.participante.aeroportoOrigem = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Aeroporto de Origem</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Cidade de Embarque</label>
                                                        <ReactSelect
                                                            value={{ value: dados.participante?.aereomunicipioembarque ?? "", label: dados.participante?.nomeAereomunicipioembarque }}
                                                            onChange={(e) => {
                                                                dados.participante.aereomunicipioembarque = e.value;
                                                                dados.participante.nomeAereomunicipioembarque = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/municipio',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        uf: dados.participante?.enderecouf ?? null
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <Tabs className="justify-content-end" defaultActiveKey="colunasnomeaveis">
                                                <Tab eventKey="colunasnomeaveis" title="Colunas Nomeáveis">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna1" placeholder={dados.participante?.evento?.nomecoluna1}
                                                                    value={dados.participante?.coluna1 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna1 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna1}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna2" placeholder={dados.participante?.evento?.nomecoluna2}
                                                                    value={dados.participante?.coluna2 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna2 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna2}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna3" placeholder={dados.participante?.evento?.nomecoluna3}
                                                                    value={dados.participante?.coluna3 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna3 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna3}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna4" placeholder={dados.participante?.evento?.nomecoluna4}
                                                                    value={dados.participante?.coluna4 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna4 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna4}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna5" placeholder={dados.participante?.evento?.nomecoluna5}
                                                                    value={dados.participante?.coluna5 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna5 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna5}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna6" placeholder={dados.participante?.evento?.nomecoluna6}
                                                                    value={dados.participante?.coluna6 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna6 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna6}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna7" placeholder={dados.participante?.evento?.nomecoluna7}
                                                                    value={dados.participante?.coluna7 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna7 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna7}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna8" placeholder={dados.participante?.evento?.nomecoluna8}
                                                                    value={dados.participante?.coluna8 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna8 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna8}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna9" placeholder={dados.participante?.evento?.nomecoluna9}
                                                                    value={dados.participante?.coluna9 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna9 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna9}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna10" placeholder={dados.participante?.evento?.nomecoluna10}
                                                                    value={dados.participante?.coluna10 ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.coluna10 = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.participante?.evento?.nomecoluna10}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="hotel" title={<><input type="checkbox" readOnly checked={dados.participante?.hotelhospedagem === 'sim' ? true : false}></input> Hospedagem</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Hospedagem?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.participante?.hotelhospedagem ?? "", label: dados.participante?.nomeHotelhospedagem }}
                                                                    onChange={(e) => {
                                                                        dados.participante.hotelhospedagem = e.value;
                                                                        dados.participante.nomeHotelhospedagem = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hotelnome" placeholder="Hotel"
                                                                    value={dados.participante?.hotelnome ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.hotelnome = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hotel</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hoteltipoquarto" placeholder="Tipo de Quarto"
                                                                    value={dados.participante?.hoteltipoquarto ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.hoteltipoquarto = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Tipo de Quarto</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"hoteldatacheckin"}
                                                                label="Data do checkin"
                                                                value={dados.participante?.hoteldatacheckin ?? null}
                                                                onChange={(date) => {
                                                                    dados.participante.hoteldatacheckin = date;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hotelhoracheckin" placeholder="Hora do checkin"
                                                                    value={dados.participante?.hotelhoracheckin ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.hotelhoracheckin = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora do checkin</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"hoteldatacheckout"}
                                                                label="Data do checkout"
                                                                value={dados.participante?.hoteldatacheckout ?? null}
                                                                onChange={(date) => {
                                                                    dados.participante.hoteldatacheckout = date;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hotelhoracheckout" placeholder="Hora do checkout"
                                                                    value={dados.participante?.hotelhoracheckout ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.hotelhoracheckout = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora do checkout</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="hotelobservacao"
                                                                    placeholder="Observação"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.hotelobservacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.hotelobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="aereo" title={<><input type="checkbox" readOnly checked={dados.participante?.aereo === 'sim' ? true : false}></input> Aéreo</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Aéreo?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.participante?.aereo ?? "", label: dados.participante?.nomeAereo }}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereo = e.value;
                                                                        dados.participante.nomeAereo = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <h4>Dados Ida</h4>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidadata" placeholder="Data"
                                                                    value={dados.participante?.aereoidadata ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidadata = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Data</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaorigem" placeholder="Origem"
                                                                    value={dados.participante?.aereoidaorigem ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidaorigem = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Origem</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidacompanhia" placeholder="Companhia Aérea"
                                                                    value={dados.participante?.aereoidacompanhia ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidacompanhia = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Companhia Aérea</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidavoo" placeholder="Vôo"
                                                                    value={dados.participante?.aereoidavoo ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidavoo = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Vôo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidahorasaida" placeholder="Hora Saída"
                                                                    value={dados.participante?.aereoidahorasaida ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidahorasaida = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Saída</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidahorachegada" placeholder="Hora Chegada"
                                                                    value={dados.participante?.aereoidahorachegada ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidahorachegada = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Chegada</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidadestino" placeholder="Destino"
                                                                    value={dados.participante?.aereoidadestino ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidadestino = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Destino</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidalocalizador" placeholder="Localizador"
                                                                    value={dados.participante?.aereoidalocalizador ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidalocalizador = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Localizador</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaeticket" placeholder="E-ticket"
                                                                    value={dados.participante?.aereoidaeticket ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidaeticket = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">E-ticket</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaconexao" placeholder="Conexão"
                                                                    value={dados.participante?.aereoidaconexao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidaconexao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Conexão</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoidaobservacao"
                                                                    placeholder="Observação Ida"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.aereoidaobservacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoidaobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Ida</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <h4>Dados Retorno</h4>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornodata" placeholder="Data"
                                                                    value={dados.participante?.aereoretornodata ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornodata = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Data</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoorigem" placeholder="Origem"
                                                                    value={dados.participante?.aereoretornoorigem ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornoorigem = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Origem</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornocompanhia" placeholder="Companhia Aérea"
                                                                    value={dados.participante?.aereoretornocompanhia ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornocompanhia = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Companhia Aérea</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornovoo" placeholder="Vôo"
                                                                    value={dados.participante?.aereoretornovoo ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornovoo = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Vôo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornohorasaida" placeholder="Hora Saída"
                                                                    value={dados.participante?.aereoretornohorasaida ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornohorasaida = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Saída</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornohorachegada" placeholder="Hora Chegada"
                                                                    value={dados.participante?.aereoretornohorachegada ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornohorachegada = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Chegada</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornodestino" placeholder="Destino"
                                                                    value={dados.participante?.aereoretornodestino ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornodestino = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Destino</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornolocalizador" placeholder="Localizador"
                                                                    value={dados.participante?.aereoretornolocalizador ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornolocalizador = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Localizador</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoeticket" placeholder="E-ticket"
                                                                    value={dados.participante?.aereoretornoeticket ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornoeticket = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">E-ticket</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoconexao" placeholder="Conexão"
                                                                    value={dados.participante?.aereoretornoconexao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornoconexao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Conexão</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoretornoobservacao"
                                                                    placeholder="Observação Retorno"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.aereoretornoobservacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.aereoretornoobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Retorno</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr />
                                                    <h4>Opções de aéreo disponíveis</h4>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                                <h4>Ida <FaPlaneDeparture size={20} /></h4>
                                                                <div className="table-responsive">
                                                                    <table className='table mb-0'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Data</th>
                                                                                <th>Cia</th>
                                                                                <th>Voo</th>
                                                                                <th>Origem</th>
                                                                                <th>Destino</th>
                                                                                <th>Saida</th>
                                                                                <th>Chegada</th>
                                                                            </tr>
                                                                            {dados.participante?.evento?.eventoaereo ? dados.participante?.evento.eventoaereo.map((row, rowIndex) => {
                                                                                return [
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idadata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idacompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idavoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idaorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idadestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idahorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].idahorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ];
                                                                            }) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                                <h4>Volta <FaPlaneArrival size={20} /></h4>
                                                                <div className="table-responsive">
                                                                    <table className='table mb-0'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Data</th>
                                                                                <th>Cia</th>
                                                                                <th>Voo</th>
                                                                                <th>Origem</th>
                                                                                <th>Destino</th>
                                                                                <th>Saida</th>
                                                                                <th>Chegada</th>
                                                                            </tr>
                                                                            {dados.participante?.evento?.eventoaereo ? dados.participante?.evento.eventoaereo.map((row, rowIndex) => {
                                                                                return [
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornodata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornocompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornovoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornoorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornodestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornohorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.participante?.evento.eventoaereo[rowIndex].retornohorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ];
                                                                            }) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="inscricao" title={<><input type="checkbox" readOnly checked={dados.participante?.inscricao === 'sim' ? true : false}></input> Inscrição</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Inscrição?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.participante?.inscricao ?? "", label: dados.participante?.nomeInscricao }}
                                                                    onChange={(e) => {
                                                                        dados.participante.inscricao = e.value;
                                                                        dados.participante.nomeInscricao = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="inscricaonumero" placeholder="Número de Inscrição"
                                                                    value={dados.participante?.inscricaonumero ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.inscricaonumero = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Número de Inscrição</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="transfer" title={<><input type="checkbox" readOnly checked={dados.participante?.transfer === 'sim' ? true : false}></input> Transfer</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Transfer?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.participante?.transfer ?? "", label: dados.participante?.nomeTransfer }}
                                                                    onChange={(e) => {
                                                                        dados.participante.transfer = e.value;
                                                                        dados.participante.nomeTransfer = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="transferobservacao"
                                                                    placeholder="Observação Transfer"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.transferobservacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.transferobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Transfer</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Transfer Extra?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.participante?.transferextra ?? "", label: dados.participante?.nomeTransferextra }}
                                                                    onChange={(e) => {
                                                                        dados.participante.transferextra = e.value;
                                                                        dados.participante.nomeTransferextra = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="transferobservacao"
                                                                    placeholder="Observação Transfer Extra"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.transferextraobservacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.transferextraobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Transfer Extra</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="observacao" title="Observações Importantes">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="observacao"
                                                                    placeholder="Observações Importantes"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.participante?.observacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dados.participante.observacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observações Importantes</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                            <FaSave size={20} /> Salvar</button>
                                    </div>
                                </div>
                            </form>
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <h4>Dados de Histórico</h4>
                                            <form className="form" onSubmit={(e) => {
                                                //valida se tem status
                                                var modalsituacao = document.querySelector("#modalsituacao");
                                                if (dadosModalHistorico.situacao === undefined || dadosModalHistorico.situacao === null || dadosModalHistorico.situacao === '') {
                                                    toast.error('É necessário preencher o status');
                                                    setTimeout(() => {
                                                        modalsituacao.focus();
                                                    }, 500);
                                                    e.preventDefault();
                                                    return false;
                                                }

                                                //valida se tem data e hora da situação
                                                var campodatanotificacao = document.querySelector("#datanotificacao");
                                                var campohoranotificacao = document.querySelector("#horanotificacao");
                                                if (dadosModalHistorico.pedirdata === "sim") {
                                                    //if (dadosModalHistorico.datanotificacao === undefined || dadosModalHistorico.datanotificacao === null || dadosModalHistorico.datanotificacao === '') {
                                                    //    toast.error('É necessário preencher a data');
                                                    //    setTimeout(() => {
                                                    //        campodatanotificacao.focus();
                                                    //    }, 500);
                                                    //    e.preventDefault();
                                                    //    return false;
                                                    //}

                                                    if (dadosModalHistorico.datanotificacao === undefined || dadosModalHistorico.datanotificacao === null || dadosModalHistorico.datanotificacao === '') {
                                                    } else {
                                                        if (dadosModalHistorico.horanotificacao === undefined || dadosModalHistorico.horanotificacao === null || dadosModalHistorico.horanotificacao === '') {
                                                            toast.error('É necessário preencher a hora');
                                                            setTimeout(() => {
                                                                campohoranotificacao.focus();
                                                            }, 500);
                                                            e.preventDefault();
                                                            return false;
                                                        }
                                                    }
                                                }

                                                var datanotificacao = null;
                                                var horanotificacao = null;
                                                if (dadosModalHistorico.datanotificacao !== undefined && dadosModalHistorico.datanotificacao !== null && dadosModalHistorico.datanotificacao !== '') {
                                                    datanotificacao = dataMysql(dadosModalHistorico.datanotificacao);
                                                    if (dadosModalHistorico.horanotificacao !== undefined && dadosModalHistorico.horanotificacao !== null && dadosModalHistorico.horanotificacao !== '') {
                                                        horanotificacao = dadosModalHistorico.horanotificacao;
                                                    }
                                                }

                                                //tem observação
                                                api({
                                                    method: 'post',
                                                    action: '/participanteHistoricoSituacao',
                                                    token: token,
                                                    data: {
                                                        evento: dados.participante.evento.id,
                                                        participante: dados.participante?.id,
                                                        situacao: dadosModalHistorico.situacao,
                                                        historico: dadosModalHistorico.historico,
                                                        datanotificacao: datanotificacao,
                                                        horanotificacao: horanotificacao,
                                                    }
                                                })
                                                    .then(function (response) {
                                                        //limpa campos
                                                        dadosModalHistorico.pedirdata = "nao";
                                                        dadosModalHistorico.datanotificacao = null;
                                                        dadosModalHistorico.horanotificacao = '';
                                                        dadosModalHistorico.historico = '';
                                                        dadosModalHistorico.situacao = null;
                                                        dadosModalHistorico.nomeSituacao = null;
                                                        setDadosModalHistorico({ ...dadosModalHistorico });
                                                        buscarHistorico(dados.participante?.id);
                                                        toast.success("Registrado com sucesso!");
                                                    })
                                                    .catch(function (error) {
                                                        toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                    });
                                                e.preventDefault();
                                            }}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <ReactSelect
                                                                id="modalsituacao"
                                                                value={{ value: dadosModalHistorico.situacao, label: dadosModalHistorico.nomeSituacao, pedirdata: dadosModalHistorico.pedirdata }}
                                                                onChange={(e) => {
                                                                    dadosModalHistorico.situacao = e.value;
                                                                    dadosModalHistorico.nomeSituacao = e.label;
                                                                    dadosModalHistorico.pedirdata = e.pedirdata;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/eventoParticipanteSituacao',
                                                                        token: token,
                                                                        params: {
                                                                            situacaoRegistro: "ativo",
                                                                            nome: input
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome, pedirdata: registro.pedirdata }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {(dadosModalHistorico.pedirdata === "sim") ?
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                title="Opcional"
                                                                id={"datanotificacao"}
                                                                label="Data"
                                                                value={dadosModalHistorico.datanotificacao ?? ""}
                                                                onChange={(date) => {
                                                                    dadosModalHistorico.datanotificacao = date;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask
                                                                    title="Opcional"
                                                                    mask="99:99"
                                                                    className="form-control form-control-lg"
                                                                    id="horanotificacao"
                                                                    placeholder="Hora"
                                                                    value={dadosModalHistorico.horanotificacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dadosModalHistorico.horanotificacao = e.target.value;
                                                                        setDadosModalHistorico({ ...dadosModalHistorico });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-floating form-group">
                                                            <textarea
                                                                className="form-control form-control-lg"
                                                                id="modalhistorico"
                                                                placeholder="Observação"
                                                                style={{ minHeight: "100px" }}
                                                                value={dadosModalHistorico.historico}
                                                                onChange={(e) => {
                                                                    dadosModalHistorico.historico = e.target.value;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                            />
                                                            <label htmlFor="floatingInput">Observação</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex justify-content-end">
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSave size={20} /> Criar Observação</button>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="table-responsive">
                                                <DataTable
                                                    noContextMenu="true"
                                                    actions={tableActions}
                                                    selectableRows="false"
                                                    selectableRowsHighlight="true"
                                                    onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                        setTableSelect({ allSelected, selectedCount, selectedRows });
                                                    }}
                                                    noDataComponent="Nenhum registro"
                                                    paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                                    pagination="true"
                                                    className="table mb-0"
                                                    columns={[
                                                        {
                                                            name: 'Operador',
                                                            selector: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            cell: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            width: "200px",
                                                        },
                                                        {
                                                            name: 'Data',
                                                            selector: (row) => {
                                                                return row.created_at ?? "";
                                                            },
                                                            width: "120px",
                                                            cell: (row) => {
                                                                if (row.created_at) {
                                                                    return moment(row.created_at).format("DD/MM/YYYY HH:mm");
                                                                } else {
                                                                    return "";
                                                                }
                                                            },
                                                        },
                                                        {
                                                            name: 'Status',
                                                            selector: (row) => {
                                                                return row.situacao?.nome ?? "";
                                                            },
                                                            cell: (row) => {
                                                                return row.situacao?.nome ?? "";
                                                            },
                                                            width: "200px",
                                                        },
                                                        {
                                                            name: 'Observação',
                                                            selector: (row) => {
                                                                return row.historico ?? "";
                                                            },
                                                            cell: (row) => {
                                                                if (row.historico) {
                                                                    return row.historico;
                                                                } else {
                                                                    return "";
                                                                }
                                                            },
                                                        },
                                                        {
                                                            cell: (row, index) => {
                                                                return <ModalButton
                                                                    title="Alterar"
                                                                    icon={<FaEdit size={20} />}
                                                                    color="primary"
                                                                    onHide={() => {
                                                                        api({
                                                                            method: 'put',
                                                                            action: '/participanteHistoricoSituacao/' + row.id,
                                                                            token: token,
                                                                            data: {
                                                                                historico: row.historico,
                                                                            }
                                                                        })
                                                                            .then(function (response) {
                                                                                toast.success('Dados registrados com sucesso');
                                                                            })
                                                                            .catch(function (error) {
                                                                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                                            });
                                                                    }}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-sm-12'>
                                                                            <div className="form-floating form-group">
                                                                                <input type="text" className="form-control form-control-lg" id="historico" placeholder="Observação"
                                                                                    value={row.historico}
                                                                                    onChange={(e) => {
                                                                                        historico[index].historico = e.target.value;
                                                                                        setHistorico([...historico]);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="floatingInput">Observação</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ModalButton>;
                                                            },
                                                        },
                                                    ]}
                                                    data={historico}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}