import React, { useState, useEffect, useMemo } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../components/Data';
import { dataMysql } from '../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../components/FormButton';

export default function PainelEvento() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [dados, setDados] = useState({});
    const [eventos, setEventos] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const idEvento = params.get('idEvento');

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        var quarterMap = [];
        if (filtro.quarter !== undefined)
            quarterMap = filtro.quarter.map((e) => { return e.value });
        //console.log(quarterMap);
        api({
            method: 'get',
            action: '/evento',
            token: token,
            params: {
                ...filtro,
                ...{ situacao: ['ativo', 'fechado', 'cancelado'] },
                ...{ perfil: perfil },
                ...{ quarter: quarterMap },
                ...{ id: idEvento }
            }
        })
            .then(response => {
                setEventos(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        //document.querySelector("#nome")?.focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/cadastroEvento/" + tableSelect.selectedRows[0].id}
                        icon={<FaPen size={20} />}
                        color="primary">Alterar</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'rsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/cadastroEventoRsvp/" + tableSelect.selectedRows[0].id}
                        icon={<FaPen size={20} />}
                        color="primary">Dados RSVP</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'rsvp', 'listarsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/eventoParticipante/" + tableSelect.selectedRows[0].id}
                        icon={<FaUsers size={20} />}
                        color="primary">Participantes</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentebackoffice', 'backoffice'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<BsCardChecklist size={20} />}
                        onClick={() => {
                            api({
                                method: 'put',
                                action: '/evento/gerarSla/' + tableSelect.selectedRows[0].id,
                                token: token,
                            })
                                .then(function (response) {
                                    toast.success('SLA gerado com sucesso');
                                    buscar();
                                })
                                .catch(function (error) {
                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                });
                        }
                        }
                        color="primary"> Gerar SLA</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Fechar</>,
                                title: "Fechar Evento",
                                html: <>
                                    <p>Você tem certeza de deseja fechar este evento?</p>
                                    <div>Data do fechamento</div>
                                    <Data
                                        label="Data"
                                        value={dados.dataFechamento}
                                        onChange={(date) => {
                                            dados.dataFechamento = date;
                                        }}
                                    />
                                </>,
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'put',
                                            action: '/evento/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                ...{ situacao: "fechado" },
                                                ...{ dataFechamento: dataMysql(dados.dataFechamento) }
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Fechar</IconButton> : null,
                ((tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    ((tableSelect.selectedRows[0].situacao === 1) || (['diretor', 'gerentefinanceiro'].includes(perfil)))) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<GiOpenChest size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "info",
                                denyButton: "Sair",
                                confirmButton: <><FaThumbsUp size={20} /> Reabrir</>,
                                title: "Reabrir Evento",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isConfirmed) {
                                        api({
                                            method: 'put',
                                            action: '/evento/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                ...{ situacao: "ativo" }
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Reabrir</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && (!['listarsvp'].includes(perfil))) ?
                    <IconButton
                        small={true}
                        to={"/eventoHistorico/" + tableSelect.selectedRows[0].id}
                        icon={<FaList size={20} />}
                        color="primary">Histórico</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento',].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/cadastroEventoDiaQuantidade/" + tableSelect.selectedRows[0].id}
                        icon={<FaCalendarAlt size={20} />}
                        color="primary">PAX Dia</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/cadastroEventoAgenda/" + tableSelect.selectedRows[0].id}
                        icon={<FaCalendarAlt size={20} />}
                        color="primary">Agenda</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/cadastroEventoChecklist/" + tableSelect.selectedRows[0].id}
                        icon={<BsCardChecklist size={20} />}
                        color="primary">Checklist</IconButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'rsvp'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Cancelar</>,
                                html: <><div>Data de cancelamento</div>
                                    <Data
                                        label="Data"
                                        value={dados.dataCancelamento}
                                        onChange={(date) => {
                                            dados.dataCancelamento = date;
                                        }}
                                    />
                                </>,
                                title: "Cancelar Evento",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'put',
                                            action: '/evento/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                ...{ situacao: "cancelado" },
                                                ...{ dataCancelamento: dataMysql(dados.dataCancelamento) }
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Cancelar</IconButton> : null
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['diretor', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento', 'financeiro'].includes(perfil) ?
                        <NavItem to="/cadastroEvento" icon={<FaPlus size={20} />} color="primary">Novo Evento</NavItem> :
                        null}
                    {['rsvp'].includes(perfil) ?
                        <NavItem to="/cadastroEventoRsvp" icon={<FaPlus size={20} />} color="primary">Novo Evento</NavItem> :
                        null}
                    {['diretor', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                        <NavItem to="/eventoResponsavelCliente" icon={<FaList size={20} />} color="outline-dark">Responsáveis no Cliente</NavItem> :
                        null}
                    {['diretor', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                        <NavItem to="/eventoImportarKpi" icon={<FaUpload size={20} />} color="outline-dark">Importar Planilha KPI</NavItem> :
                        null}
                    {['diretor', 'gerentebackoffice', 'backoffice'].includes(perfil) ?
                        <NavItem to="/eventoImportarSanofi" icon={<FaUpload size={20} />} color="outline-dark">Importar Planilha Sanofi</NavItem> :
                        null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem to="/eventoCarta" icon={<GrMail size={20} />} color="outline-dark">Modelos de Cartas</NavItem> :
                        null}
                    {['diretor', 'rsvp'].includes(perfil) ?
                        <NavItem to="/eventoParticipanteSituacao" icon={<FaList size={20} />} color="outline-dark">Status de Participantes</NavItem> :
                        null}
                    {['diretor', 'evento'].includes(perfil) ?
                        <NavItem to="/exportarEventoExecucao" icon={<FaTable size={20} />} color="outline-dark">Exportar Evento Execução</NavItem> :
                        null}
                    {['diretor', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                        <NavItem to="/checklist" icon={<BsCardChecklist size={20} />} color="outline-dark">Checklist</NavItem> :
                        null}
                </ul>
                <div className="page-title">
                    <h3>Eventos</h3>
                </div>
                {!['listarsvp'].includes(perfil) ?
                    <section id="multiple-column-form">
                        <div className="row match-height">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label>Cliente</label>
                                                            <ReactSelect
                                                                id="cliente"
                                                                value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                                onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/participante',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            tipoCadastro: "cliente",
                                                                            situacaoRegistro: "ativo",
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map((registro) => {
                                                                                var label = registro.codigo +
                                                                                    ' - ' + registro.nome +
                                                                                    (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                    (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                    (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                    (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                return {
                                                                                    value: registro.id,
                                                                                    label: label
                                                                                };
                                                                            });
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <input autoFocus type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do evento"
                                                                onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Nome do Evento</label>
                                                        </div>
                                                    </div>
                                                    {!['rsvp', 'listarsvp'].includes(perfil) ?
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Quarter</label>
                                                                <Select
                                                                    styles={{
                                                                        control: (base, state) => ({
                                                                            ...base,
                                                                            background: "#f7faff",
                                                                        }),
                                                                    }}
                                                                    isMulti
                                                                    value={filtro.quarter}
                                                                    onChange={(e) => { setFiltro({ ...filtro, ...{ "quarter": e } }); }}
                                                                    options={[
                                                                        { value: 'Q1', label: 'Q1' },
                                                                        { value: 'Q2', label: 'Q2' },
                                                                        { value: 'Q3', label: 'Q3' },
                                                                        { value: 'Q4', label: 'Q4' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div> : null
                                                    }
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Responsável Agência</label>
                                                            <ReactSelect
                                                                value={{ value: filtro.responsavel, label: filtro.nomeResponsavel }}
                                                                onChange={e => setFiltro({ ...filtro, ...{ "responsavel": e.value, "nomeResponsavel": e.label } })}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/participante',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            tipoCadastro: "pessoa",
                                                                            situacaoRegistro: "ativo",
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map((registro) => {
                                                                                var label = registro.codigo +
                                                                                    ' - ' + registro.nome +
                                                                                    (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                    (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                    (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                    (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                return {
                                                                                    value: registro.id,
                                                                                    label: label
                                                                                };
                                                                            });
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSearch size={20} /> Buscar</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={!['listarsvp'].includes(perfil)}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            loadOptions
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                ...(
                                                    ['listarsvp'].includes(perfil) === false ?
                                                        [{
                                                            name: 'Código',
                                                            selector: row => row.codigo ?? "",
                                                            sortable: true,
                                                            width: "80px",
                                                            right: true,
                                                        },
                                                        {
                                                            name: 'Situação',
                                                            selector: row => row.situacao ?? "",
                                                            sortable: true,
                                                            width: "80px",
                                                        },
                                                        {
                                                            name: 'Identificação',
                                                            selector: row => row.identificacao ?? "",
                                                            sortable: true,
                                                            width: "110px",
                                                            right: true,
                                                        },
                                                        ] : []
                                                ),
                                                ...(
                                                    ['listarsvp'].includes(perfil) === true ?
                                                        [{
                                                            name: '',
                                                            selector: 'id',
                                                            width: "150px",
                                                            cell: (row) => {
                                                                return <IconButton
                                                                    small={true}
                                                                    to={"/eventoParticipante/" + row.id}
                                                                    icon={<FaUsers size={20} />}
                                                                    color="primary">Participantes</IconButton>;
                                                            },
                                                        }] : []
                                                ),
                                                {
                                                    name: 'Nome do evento',
                                                    selector: row => row.nome,
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.nome;
                                                    },
                                                },
                                                ...(
                                                    ['listarsvp'].includes(perfil) === false ?
                                                        [{
                                                            name: 'Cliente',
                                                            selector: row => row.cliente?.nome ?? "",
                                                            sortable: true,
                                                            width: "150px",
                                                            cell: (row) => {
                                                                if (row.cliente) {
                                                                    if (row.cliente?.enderecomunicipio) { //tem municipio
                                                                        return row.cliente.codigo + ' - ' + row.cliente.nome + ' - ' + row.cliente.enderecomunicipio.nome + ' - ' + row.cliente.enderecomunicipio.uf;
                                                                    } else { //nao tem municipio
                                                                        return row.cliente.codigo + ' - ' + row.cliente.nome;
                                                                    }
                                                                } else {
                                                                    return "";
                                                                }
                                                            },
                                                        },
                                                        {
                                                            name: 'Quarter',
                                                            selector: row => row.quarter ?? "",
                                                            sortable: true,
                                                            width: "70px",
                                                            cell: (row) => {
                                                                return row.quarter ? row.quarter : "";
                                                            },
                                                        },
                                                        {
                                                            name: 'RSVP',
                                                            selector: row => row.rsvp ?? "",
                                                            sortable: true,
                                                            width: "60px",
                                                            cell: (row) => {
                                                                return <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={row.rsvp === 'sim'}
                                                                        onChange={() => {
                                                                            row.rsvp = row.rsvp === 'sim' ? 'nao' : 'sim';
                                                                            api({
                                                                                method: 'post',
                                                                                action: '/evento/liberacaoDepartamento/' + row.id,
                                                                                token: token,
                                                                                data: {
                                                                                    departamento: 'rsvp',
                                                                                    situacao: row.rsvp,
                                                                                }
                                                                            })
                                                                                .then(function (response) {
                                                                                    //alert("Evento cancelado com sucesso!");
                                                                                })
                                                                                .catch(function (error) {
                                                                                    row.rsvp = row.rsvp === 'sim' ? 'nao' : 'sim';
                                                                                    setEventos([...eventos]);
                                                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                                                });

                                                                            setEventos([...eventos]);
                                                                        }}
                                                                    />
                                                                </div>;
                                                            },
                                                        },
                                                        {
                                                            name: 'BackOffice',
                                                            selector: row => row.backoffice ?? "",
                                                            sortable: true,
                                                            width: "80px",
                                                            cell: (row) => {
                                                                return <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={row.backoffice === 'sim'}
                                                                        onChange={() => {
                                                                            row.backoffice = row.backoffice === 'sim' ? 'nao' : 'sim';
                                                                            api({
                                                                                method: 'post',
                                                                                action: '/evento/liberacaoDepartamento/' + row.id,
                                                                                token: token,
                                                                                data: {
                                                                                    departamento: 'backoffice',
                                                                                    situacao: row.backoffice,
                                                                                }
                                                                            })
                                                                                .then(function (response) {
                                                                                    //alert("Evento cancelado com sucesso!");
                                                                                })
                                                                                .catch(function (error) {
                                                                                    row.backoffice = row.backoffice === 'sim' ? 'nao' : 'sim';
                                                                                    setEventos([...eventos]);
                                                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                                                });

                                                                            setEventos([...eventos]);
                                                                        }}
                                                                    />
                                                                </div>;
                                                            },
                                                        },
                                                        {
                                                            name: 'Financeiro',
                                                            selector: row => row.financeiro ?? "",
                                                            sortable: true,
                                                            width: "80px",
                                                            cell: (row) => {
                                                                return <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={row.financeiro === 'sim'}
                                                                        onChange={() => {
                                                                            row.financeiro = row.financeiro === 'sim' ? 'nao' : 'sim';
                                                                            api({
                                                                                method: 'post',
                                                                                action: '/evento/liberacaoDepartamento/' + row.id,
                                                                                token: token,
                                                                                data: {
                                                                                    departamento: 'financeiro',
                                                                                    situacao: row.financeiro,
                                                                                }
                                                                            })
                                                                                .then(function (response) {
                                                                                    //alert("Evento cancelado com sucesso!");
                                                                                })
                                                                                .catch(function (error) {
                                                                                    row.financeiro = row.financeiro === 'sim' ? 'nao' : 'sim';
                                                                                    setEventos([...eventos]);
                                                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                                                });

                                                                            setEventos([...eventos]);
                                                                        }}
                                                                    />
                                                                </div>;
                                                            },
                                                        },
                                                        {
                                                            name: 'Complexidade',
                                                            selector: row => row.complexidade ?? "",
                                                            sortable: true,
                                                            width: "80px",
                                                            cell: (row) => {
                                                                return row.complexidade ? row.complexidade : "";
                                                            },
                                                        },
                                                        {
                                                            name: 'Data',
                                                            selector: row => row.data ?? "",
                                                            sortable: true,
                                                            width: "110px",
                                                            cell: (row) => {
                                                                var dataCompetencia = row.dataCompetencia ? " " + moment(row.dataCompetencia).format("MM/YYYY") : "";
                                                                var dataInicio = row.data ? " " + moment(row.data).format("DD/MM/YYYY") : "";
                                                                dataInicio = row.replanejadoInicio ? " " + moment(row.replanejadoInicio).format("DD/MM/YYYY") : dataInicio;
                                                                var dataFim = row.dataFim ? " " + moment(row.dataFim).format("DD/MM/YYYY") : "";
                                                                dataFim = row.replanejadoFim ? " " + moment(row.replanejadoFim).format("DD/MM/YYYY") : dataFim;
                                                                var dataCancelamento = row.dataCancelamento ? " Cancelado: " + moment(row.dataCancelamento).format("DD/MM/YYYY") : "";
                                                                var dataFechamento = row.dataFechamento ? " Fechado: " + moment(row.dataFechamento).format("DD/MM/YYYY") : "";
                                                                if (dataInicio === dataFim) {
                                                                    return dataCompetencia + dataInicio + dataFechamento + dataCancelamento;
                                                                } else {
                                                                    return dataCompetencia + dataInicio + dataFim + dataFechamento + dataCancelamento;
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: 'Responsável',
                                                            selector: row => row.responsavel?.nome ?? "",
                                                            sortable: true,
                                                            width: "90px",
                                                            cell: (row) => {
                                                                return row.responsavel ? row.responsavel.nome : "";
                                                            },
                                                        },
                                                        ] : []
                                                ),
                                            ]}
                                            data={eventos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
