import React, { useState, useEffect, useMemo } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable, FaForward, FaBackward, FaEye } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../components/Data';
import { dataMysql } from '../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../components/FormButton';

export default function ListaBriefingCliente() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [dados, setDados] = useState({});
    const [eventos, setEventos] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        var quarterMap = [];
        if (filtro.quarter !== undefined)
            quarterMap = filtro.quarter.map((e) => { return e.value });
        //console.log(quarterMap);
        api({
            method: 'get',
            action: '/eventoBriefing',
            token: token,
            params: {
                ...filtro
            }
        })
            .then(response => {
                setEventos(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        document.querySelector("#nome")?.focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate sidebar={false}>
            <div style={{
                backgroundImage: "url(/imagens/evento.png)",
                backgroundPositionY: "50%",
                minHeight: "400px",
                width: "100%",
                top: "-50px",
                position: "fixed",
            }}>
                <span className="mask bg-primary opacity-6"></span>
            </div>
            <div className="main-content container-fluid">
                <ul className="nav"
                    style={{ position: "relative" }} >
                    <NavItem to="/briefingCliente" icon={<FaBackward size={20} />} color="primary"> Voltar</NavItem>
                </ul>
                <section className="section">
                    <div className="row maisMargem">
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-12">
                            <div className="card">
                                <h1 style={{ position: "absolute", top: "10px", left: "15px", zIndex: "1" }}>Briefings</h1>
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            //actions={tableActions}
                                            //selectableRows={!['listarsvp'].includes(perfil)}
                                            //selectableRowsHighlight="true"
                                            //onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                            //    setTableSelect({ allSelected, selectedCount, selectedRows });
                                            //}}
                                            loadOptions
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: row => row.evento?.codigo ?? "",
                                                    cell: (row) => {
                                                        return <IconButton
                                                            small={true}
                                                            to={'/visualizarBriefing/' + row.id}
                                                            icon={<FaEye size={20} />}
                                                            color="primary">Abrir</IconButton>;
                                                    },
                                                    width: "90px",
                                                },
                                                {
                                                    name: '',
                                                    selector: row => row.evento?.codigo ?? "",
                                                    cell: (row) => {
                                                        if (['diretor', 'gerente', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil)) { //gerentes sempre alteram
                                                            return <IconButton
                                                                small={true}
                                                                icon={<GiOpenChest size={20} />}
                                                                onClick={() => {
                                                                    Alert({
                                                                        icon: "info",
                                                                        denyButton: "Sair",
                                                                        confirmButton: <><FaThumbsUp size={20} /> Reabrir</>,
                                                                        title: "Reabrir Briefing",
                                                                        callBack: (result) => {
                                                                            if (result.isConfirmed) {
                                                                                api({
                                                                                    method: 'put',
                                                                                    action: '/briefing/reabrir/' + row.id,
                                                                                    token: token,
                                                                                })
                                                                                    .then(function (response) {
                                                                                        buscar();
                                                                                    })
                                                                                    .catch(function (error) {
                                                                                        //alert(error)
                                                                                    });
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                }
                                                                color="primary"> Reabrir</IconButton>;
                                                        }

                                                        if (['briefing', 'cliente'].includes(perfil)) {
                                                            var caminho = '/cadastroBriefingAdvisoryBoard/';
                                                            if (row.modelo === 'AdvisoryBoard') {
                                                                caminho = '/cadastroBriefingAdvisoryBoard/';
                                                            }
                                                            if (row.modelo === 'EventoTerceiro') {
                                                                caminho = '/cadastroBriefingEventoTerceiro/';
                                                            }
                                                            if (row.modelo === 'OrganizadoSede') {
                                                                caminho = '/cadastroBriefingOrganizadoSede/';
                                                            }
                                                            if (row.modelo === 'OrganizadoForcaVendas') {
                                                                caminho = '/cadastroBriefingOrganizadoForcaVendas/';
                                                            }
                                                            if ((row.evento?.situacao ?? "") === 'rascunhoBriefing') {
                                                                return <IconButton
                                                                    small={true}
                                                                    to={caminho + row.id}
                                                                    icon={<FaPen size={20} />}
                                                                    color="primary">Alterar</IconButton>;
                                                            }
                                                        } //perfil briefing

                                                        return null;
                                                    },
                                                    width: "100px",
                                                },
                                                {
                                                    name: 'Status',
                                                    selector: row => row.evento?.situacao ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    cell: (row) => {
                                                        if (row.evento?.situacao === 'rascunhoBriefing') {
                                                            return <span className="badge bg-warning">Rascunho</span>;
                                                        }
                                                        if (row.evento?.situacao === 'briefing') {
                                                            return <span className="badge bg-success">Enviado</span>;
                                                        }
                                                        return null;
                                                    }
                                                },
                                                {
                                                    name: 'Código',
                                                    selector: row => row.evento?.codigo ?? "",
                                                    sortable: true,
                                                    width: "70px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'ID',
                                                    selector: row => row.evento?.identificacao ?? "",
                                                    width: "90px",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Nome do evento',
                                                    selector: row => row.evento?.nome,
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Requisitante',
                                                    selector: row => row.requisitanteEvento ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Data Envio',
                                                    selector: row => row.dataEnvio ?? "",
                                                    sortable: true,
                                                    width: "135px",
                                                    cell: (row) => {
                                                        var dataEnvio = row.dataEnvio ? " " + moment(row.dataEnvio).format("DD/MM/YYYY HH:mm") : "";
                                                        return dataEnvio;
                                                    }
                                                },
                                                {
                                                    name: 'Data',
                                                    selector: row => row.evento?.data ?? "",
                                                    sortable: true,
                                                    width: "120px",
                                                    cell: (row) => {
                                                        var dataInicio = row.evento?.data ? " " + moment(row.evento?.data).format("DD/MM/YYYY") : "";
                                                        var dataFim = row.evento?.dataFim ? " " + moment(row.evento?.dataFim).format("DD/MM/YYYY") : "";
                                                        if (dataInicio === dataFim) {
                                                            return dataInicio;
                                                        } else {
                                                            return dataInicio + dataFim;
                                                        }
                                                    }
                                                },
                                            ]}
                                            data={eventos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
