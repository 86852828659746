import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaBan, FaCheck, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import { bin2hex, extract } from '../../../util/util';
import { toast } from 'react-toastify';
import MostrarHTML from '../../../components/MostrarHtml';

export default function CadastroEventoParticipanteAceitarConvite({ match }) {
    let elementoAssinatura = null;
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [concordaConvite, setConcordaConvite] = useState(false);
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const contrato = params.get('contrato');

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });

            dadosRecebidos.evento.camposQuestionario = dadosRecebidos.evento.camposQuestionario ?
                JSON.parse(dadosRecebidos.evento.camposQuestionario) :
                {
                    "tipoparticipante": "sim",
                    "nome": "sim",
                    "assinaturaListaPresenca": "sim",
                    "telefone": "sim",
                    "email": "sim",
                    "especialidade": "sim",
                    "rg": "sim",
                    "crm": "sim",
                    "crmuf": "sim",
                    "datanascimento": "sim",
                    "cpf": "sim",
                    "enderecologradouro": "sim",
                    "endereconumero": "sim",
                    "enderecocomplemento": "sim",
                    "enderecocep": "sim",
                    "enderecobairro": "sim",
                    "enderecomunicipionome": "sim",
                    "enderecomunicipiouf": "sim",
                };

            if ((dadosRecebidos.datacartaassinada ?? "") === '') {//se ainda nao aceitou
                api({
                    method: "get",
                    action: '/eventoCarta/visualizarCartaHtml/' + id,
                    token: token,
                    params: {
                        tipoCarta: "modelocartaconvite",
                    }
                }).then(response => {
                    setDados({
                        ...dados,
                        ...dadosRecebidos,
                        ...{ htmlCartaConvite: response.data.data.htmlCarta }
                    })
                }).catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
            } else { //já aceitou a carta convite
                history.push("/cadastroEventoParticipanteConvite/" + id);
            }
        }).catch(function (error) {
            toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
        });
    };//buscar

    const validar = () => {
        if ((dados.evento?.camposQuestionario?.vinculoOrgaoPublico ?? "nao") === "sim") {
            var vinculoOrgaoPublico = document.querySelector("#vinculoOrgaoPublico");
            if ((dados.vinculoOrgaoPublico ?? '') === '') {
                toast.error('É necessário preencher se tem vínculo com órgão público');
                setTimeout(() => {
                    vinculoOrgaoPublico.focus();
                }, 500);
                return false;
            }
        }

        return true;
    };

    const salvar = (aceitouConvite) => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        api({
            method: 'put',
            action: '/participante/aceitarConvite/' + id,
            token: token,
            data: {
                aceitouConvite: aceitouConvite ? 'sim' : 'nao',
                contrato: contrato ? 'sim' : 'nao',
                vinculoOrgaoPublico: dados.vinculoOrgaoPublico ?? null,
                imagemassinatura: dados.imagemassinatura ?? null,
                binarioImagemAssinatura: elementoAssinatura ? bin2hex(elementoAssinatura.getCanvas().toDataURL('image/png')) : null
            },
        })
            .then(function (response) {
                if (aceitouConvite) {
                    if (contrato) { //está na rotina de assinar o contrato digital
                        history.push("/cadastroEventoParticipanteContratoEnviado/" + (dados.evento?.id ?? ""));
                    } else { //está na rotina de confirmação de dados
                        if ((dados.evento?.modelotermoaceite ?? "") !== '') { //se tiver termo de aceite
                            history.push("/cadastroEventoParticipanteAceitarTermo/" + id); //mostrar termo
                        } else { //caso nao tenha termo de aceite
                            history.push("/cadastroEventoParticipanteConvite/" + id); //vai direto para cadastro de dados
                        }
                    }
                } else {
                    history.push("/cadastroEventoParticipanteConviteRecusado/" + dados.evento.id);
                }
            })
            .catch(function (error) {
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        if (dados.base64assinatura) {
            if (elementoAssinatura)
                elementoAssinatura.fromDataURL("data:image/png;base64," + dados.base64assinatura, { ratio: 1 });
        }
    },
        // eslint-disable-next-line
        [dados.base64assinatura]);

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Carta Convite</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <MostrarHTML
                                                    htmlContent={dados.htmlCartaConvite ?? null}
                                                />
                                            </div>
                                        </div>

                                        {(dados.evento?.camposQuestionario?.vinculoOrgaoPublico ?? "nao") === "sim" ?
                                            <div className="row" style={{ marginBottom: "20px" }}>
                                                <div className="col-sm-4">
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-check">
                                                                <div className="checkbox">
                                                                    <label>
                                                                        <input
                                                                            id="vinculoOrgaoPublico"
                                                                            type="radio"
                                                                            className="form-check-input"
                                                                            value="sim"
                                                                            checked={dados.vinculoOrgaoPublico === "sim"}
                                                                            onChange={() => {
                                                                                dados.vinculoOrgaoPublico = "sim";
                                                                                setDados({ ...dados });
                                                                            }} />
                                                                        Possuí vínculo com Órgão Público, mesmo que de forma indireta</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-check">
                                                                <div className="checkbox">
                                                                    <label>
                                                                        <input type="radio"
                                                                            className="form-check-input"
                                                                            value="nao"
                                                                            checked={dados.vinculoOrgaoPublico === "nao"}
                                                                            onChange={() => {
                                                                                dados.vinculoOrgaoPublico = "nao";
                                                                                setDados({ ...dados });
                                                                            }} />
                                                                        Não possui qualquer espécie de vínculo com Órgão Público</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}

                                        {
                                            /*
                                            {(dados.evento?.camposQuestionario?.assinaturaCartaConvite ?? "nao") === "sim" ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-12 d-flex justify-content-center">
                                                            Por favor, assine dentro do quadro
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 d-flex justify-content-center">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    width: 300, height: 300,
                                                                    className: 'sigCanvas'
                                                                }}
                                                                ref={(ref) => {
                                                                    elementoAssinatura = ref;
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 d-flex justify-content-center">
                                                            <button type='button' className="btn btn-primary me-1 mb-1"
                                                                onClick={() => {
                                                                    if (elementoAssinatura)
                                                                        elementoAssinatura.clear();
                                                                }}>
                                                                <FaTrash size={20} /> Limpar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </> : null}
                                            */
                                        }
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={concordaConvite}
                                                                checked={concordaConvite}
                                                                onChange={(value) => {
                                                                    setConcordaConvite(!concordaConvite);
                                                                }} />
                                                            Eu concordo com os termos acima</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger me-1 mb-1"
                                                    onClick={() => {
                                                        salvar(false);
                                                    }}
                                                >
                                                    <FaBan size={20} /> Não aceito o convite
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-success me-1 mb-1"
                                                    disabled={!concordaConvite}
                                                    onClick={() => {
                                                        salvar(true);
                                                    }}
                                                >
                                                    <FaCheck size={20} /> Aceito o convite
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}