import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, extract } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CadastroEventoParticipanteAereoConvite({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [marcadoIda, setMarcadoIda] = useState(null);
    const [marcadoRetorno, setMarcadoRetorno] = useState(null);
    const history = useHistory();
    let { state } = useLocation();
    //var idEvento = state?.evento ?? null;

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });

            dadosRecebidos.evento.camposQuestionario = dadosRecebidos.evento.camposQuestionario ?
                JSON.parse(dadosRecebidos.evento.camposQuestionario) :
                {
                    "tipoparticipante": "sim",
                    "nome": "sim",
                    "assinaturaListaPresenca": "sim",
                    "telefone": "sim",
                    "email": "sim",
                    "especialidade": "sim",
                    "rg": "sim",
                    "crm": "sim",
                    "crmuf": "sim",
                    "datanascimento": "sim",
                    "cpf": "sim",
                    "enderecologradouro": "sim",
                    "endereconumero": "sim",
                    "enderecocomplemento": "sim",
                    "enderecocep": "sim",
                    "enderecobairro": "sim",
                    "enderecomunicipionome": "sim",
                    "enderecomunicipiouf": "sim",
                };

            delete dadosRecebidos.aereoidaobservacao;
            delete dadosRecebidos.aereoretornoobservacao;
            setDados(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const validar = () => {
        if ((dados.evento?.camposQuestionario?.bagagemDespachada ?? "nao") === "sim") {
            var bagagemDespachada = document.querySelector("#bagagemDespachada");
            if ((dados.bagagemDespachada ?? '') === '') {
                toast.error('É necessário preencher se necessita de despacho de bagagem');
                setTimeout(() => {
                    bagagemDespachada.focus();
                }, 500);
                return false;
            }
        }

        if (marcadoIda === null) {
            if (dados.aereoidaobservacao === null || dados.aereoidaobservacao === '') {
                toast.error('É necessário escolher a opção de IDA, ou e informar o motivo');
                return false;
            }
        }
        if (marcadoRetorno === null) {
            if (dados.aereoretornoobservacao === null || dados.aereoretornoobservacao === '') {
                toast.error('É necessário escolher a opção de VOLTA, ou e informar o motivo');
                return false;
            }
        }
        return true;
    };

    const salvar = () => {

        var idEvento = dados.evento.id;

        if (!validar()) {
            return false; //para codigo aqui
        }

        api({
            method: 'put',
            action: '/participante/escolhaAereo/' + id,
            token: token,
            data: {
                escolhaaereoida: marcadoIda,
                escolhaaereoretorno: marcadoRetorno,
                aereoidaobservacao: dados.aereoidaobservacao ?? null,
                aereoretornoobservacao: dados.aereoretornoobservacao ?? null,
                bagagemDespachada: dados.bagagemDespachada ?? null,
            }
        })
            .then(function (response) {
                if (dados.transfer === 'sim' || dados.transferextra === 'sim') {
                    history.push("/cadastroEventoParticipanteTransferConvite/" + id);
                    return;
                }
                if (dados.hotelhospedagem === 'sim') {
                    history.push("/cadastroEventoParticipanteHotelConvite/" + id);
                    return;
                }
                history.push("/cadastroEventoParticipanteConviteConcluido/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Escolha de aéreo</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                {((dados.evento?.nacionalidade ?? "nacional") === "nacional") ?
                                    <>
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="card-body" style={{ padding: "0.5rem" }}>
                                                    <h4>Ida <FaPlaneDeparture size={20} /></h4>
                                                    <div className="table-responsive">
                                                        <table className='table mb-0'>
                                                            <tbody>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Data</th>
                                                                    <th>Cia</th>
                                                                    <th>Voo</th>
                                                                    <th>Origem</th>
                                                                    <th>Destino</th>
                                                                    <th>Saida</th>
                                                                    <th>Chegada</th>
                                                                </tr>
                                                                {dados.evento?.eventoaereo ? dados.evento.eventoaereo.map((row, rowIndex) => {
                                                                    return [
                                                                        <>
                                                                            <tr>
                                                                                <td width="5px">
                                                                                    <div className="form-check">
                                                                                        <div className="checkbox">
                                                                                            <label>
                                                                                                <input type="radio"
                                                                                                    name='idAereoIda'
                                                                                                    className="form-check-input"
                                                                                                    checked={dados.evento.eventoaereo[rowIndex].id === marcadoIda}
                                                                                                    onChange={(value) => {
                                                                                                        setMarcadoIda(dados.evento.eventoaereo[rowIndex].id);
                                                                                                    }} />
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idadata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idacompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idavoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idaorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idadestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idahorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].idahorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            </tr>
                                                                        </>
                                                                    ];
                                                                }) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label>As opções de voo de IDA apresentadas não atendem às minhas necessidades, preciso de um contato pelo seguinte motivo:</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoidaobservacao"
                                                                    placeholder="Motivo..."
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.aereoidaobservacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidaobservacao": e.target.value } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="card-body" style={{ padding: "0.5rem" }}>
                                                    <h4>Volta <FaPlaneArrival size={20} /></h4>
                                                    <div className="table-responsive">
                                                        <table className='table mb-0'>
                                                            <tbody>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Data</th>
                                                                    <th>Cia</th>
                                                                    <th>Voo</th>
                                                                    <th>Origem</th>
                                                                    <th>Destino</th>
                                                                    <th>Saida</th>
                                                                    <th>Chegada</th>
                                                                </tr>
                                                                {dados.evento?.eventoaereo ? dados.evento.eventoaereo.map((row, rowIndex) => {
                                                                    return [
                                                                        <>
                                                                            <tr>
                                                                                <td width="5px">
                                                                                    <div className="form-check">
                                                                                        <div className="checkbox">
                                                                                            <label>
                                                                                                <input type="radio"
                                                                                                    name='idAereoRetorno'
                                                                                                    className="form-check-input"
                                                                                                    checked={dados.evento.eventoaereo[rowIndex].id === marcadoRetorno}
                                                                                                    onChange={(value) => {
                                                                                                        setMarcadoRetorno(dados.evento.eventoaereo[rowIndex].id);
                                                                                                    }} />
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornodata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornocompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornovoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornoorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornodestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornohorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                <td>{(dados.evento.eventoaereo[rowIndex].retornohorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            </tr>
                                                                        </>
                                                                    ];
                                                                }) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label>As opções de voo de VOLTA apresentadas não atendem às minhas necessidades, preciso de um contato pelo seguinte motivo:</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoretornoobservacao"
                                                                    placeholder="Motivo..."
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.aereoretornoobservacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornoobservacao": e.target.value } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    <div className="card">
                                        <div className="card-content">
                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                <div className="table-responsive">
                                                    <table className='table mb-0'>
                                                        <tbody>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Data</th>
                                                                <th>Cia</th>
                                                                <th>Voo</th>
                                                                <th>Origem</th>
                                                                <th>Destino</th>
                                                                <th>Saida</th>
                                                                <th>Chegada</th>
                                                            </tr>
                                                            {dados.evento?.eventoaereo ? dados.evento.eventoaereo.map((row, rowIndex) => {
                                                                return [
                                                                    <>
                                                                        <tr className='lenteCinza'>
                                                                            <td width="5px" rowSpan={2}>
                                                                                <div className="form-check">
                                                                                    <div className="checkbox">
                                                                                        <label>
                                                                                            <input type="radio"
                                                                                                name='idAereoIda'
                                                                                                className="form-check-input"
                                                                                                checked={dados.evento.eventoaereo[rowIndex].id === marcadoIda}
                                                                                                onChange={(value) => {
                                                                                                    setMarcadoRetorno(dados.evento.eventoaereo[rowIndex].id);
                                                                                                    setMarcadoIda(dados.evento.eventoaereo[rowIndex].id);
                                                                                                }} />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>Ida</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idadata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idacompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idavoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idaorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idadestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idahorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idahorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                        </tr>
                                                                        <tr className='lenteCinza'>
                                                                            <td width="5px">
                                                                                Retorno
                                                                            </td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornodata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornocompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornovoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornoorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornodestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornohorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornohorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={9}></th>
                                                                        </tr>
                                                                    </>
                                                                ];
                                                            }) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label>As opções de voo apresentadas não atendem às minhas necessidades, preciso de um contato pelo seguinte motivo:</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                className="form-control form-control-lg"
                                                                id="aereoidaobservacao"
                                                                placeholder="Motivo..."
                                                                style={{ minHeight: "100px" }}
                                                                value={dados.aereoidaobservacao}
                                                                onChange={e => setDados({ ...dados, ...{ "aereoidaobservacao": e.target.value } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(dados.evento?.camposQuestionario?.bagagemDespachada ?? "nao") === "sim" ?
                                    <div className="card">
                                        <div className="card-content">
                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Necessita de despacho de bagagem?</label>
                                                            <ReactSelect
                                                                id={"bagagemDespachada"}
                                                                value={{ value: dados.bagagemDespachada, label: dados.nomeBagagemDespachada }}
                                                                onChange={e => setDados({ ...dados, ...{ "bagagemDespachada": e.value, "nomeBagagemDespachada": e.label } })}
                                                                options={[
                                                                    { value: "nao", label: "Não" },
                                                                    { value: "sim", label: "Sim" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}

                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body" style={{ padding: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4>Informações Importantes de Aéreo</h4>
                                                    <p>
                                                        A opção de aéreo escolhida será enviada para emissão e só será emitida conforme disponibilidade. Entraremos em contato caso seja necessário validar algum ajuste.
                                                    </p>
                                                    <p>
                                                        <div style={{ fontStyle: 'italic' }}>Documentação obrigatória para essa viagem:</div>
                                                        {((dados.evento?.nacionalidade ?? "nacional") === 'nacional') ?
                                                            <strong>R.G. com emissão inferior a 10 anos ou CNH válida</strong>
                                                            :
                                                            <strong>
                                                                Passaporte com validade mínima de 6 meses. Vistos consulares dentro validade quando necessário<br />
                                                                Favor enviar copia do passaporte/RG e visto (se necessário) para o e-mail: {dados.evento?.emailremetente ?? ""}
                                                            </strong>
                                                        }
                                                    </p>
                                                    <p>
                                                        A {dados.evento?.cliente?.nomefantasia} se responsabiliza exclusivamente pelo patrocínio das passagens aéreas necessárias ao seu comparecimento no evento e período descrito no convite.<br />
                                                        Alterações serão de responsabilidade do convidado.
                                                    </p>
                                                    <p>
                                                        Favor nos confirmar se seu nome está descrito da mesma maneira que em seu documento original apresentado para embarque.<br />
                                                        Essa informação é muito importante para evitarmos transtornos no momento de seu embarque.<br />
                                                        Observações Importantes:<br />
                                                        - Informamos que qualquer tipo de alteração após a emissão do bilhete, será de total responsabilidade do participante.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}