import React, { useState } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaBackward, FaForward, FaList, FaPen, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import IconButton from '../../components/IconButton';
import NavItem from '../../components/NavItem';

export default function CadastroModeloBriefing({ match }) {
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const history = useHistory();

    return (
        <ErpTemplate sidebar={['briefing', 'cliente'].includes(perfil) ? false : true}>
            <div className='imagemFundoEvento' style={{ backgroundImage: "url(/imagens/evento.png)" }}>
                <span className="mask bg-primary"></span>
            </div>
            <div className="main-content container-fluid">
                <ul className="nav" style={{ position: "relative" }} >
                    {['briefing', 'cliente'].includes(perfil) ?
                        <NavItem to="/briefingCliente" icon={<FaBackward size={20} />} color="primary"> Voltar</NavItem> : null}
                </ul>
                <section className="section">
                    <div className="conjuntoBoxBriefing">
                        <div class="boxBriefing">
                            <p class="boxBriefing-text">Advisory Board</p>
                            <div class="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/cadastroBriefingAdvisoryBoard" icon={<FaPen size={20} />} color="default">Novo evento</IconButton>
                            </div>
                        </div>
                        <div class="boxBriefing">
                            <p class="boxBriefing-text">Evento de Terceiro - Tipo 1, Tipo 2 e Simposio</p>
                            <div class="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/cadastroBriefingEventoTerceiro" icon={<FaPen size={20} />} color="default">Novo evento</IconButton>
                            </div>
                        </div>
                        <div class="boxBriefing">
                            <p class="boxBriefing-text">Evento Sanofi Organizado pela Sede / Master Class</p>
                            <div class="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/cadastroBriefingOrganizadoSede" icon={<FaPen size={20} />} color="default">Novo evento</IconButton>
                            </div>
                        </div>
                        <div class="boxBriefing">
                            <p class="boxBriefing-text">Evento Sanofi Organizado pela Força de Vendas ou Aérea Médica</p>
                            <div class="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/cadastroBriefingOrganizadoForcaVendas" icon={<FaPen size={20} />} color="default">Novo evento</IconButton>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}