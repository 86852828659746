import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, extract } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CadastroEventoParticipanteTransferConvite({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    //var idEvento = state?.evento ?? null;

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });
            setDados(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const validar = () => {
        return true;
    };

    const salvar = () => {

        var idEvento = dados.evento.id;

        if (!validar()) {
            return false; //para codigo aqui
        }

        api({
            method: 'put',
            action: '/participante/escolhaTransfer/' + id,
            token: token,
            data: {
                transferextra: (dados.transferextraobservacao ?? '') === '' ? 'nao' : 'sim',
                transferextraobservacao: dados.transferextraobservacao ?? null,
                transferobservacao: dados.transferobservacao ?? null,
            }
        })
            .then(function (response) {
                if (dados.hotelhospedagem === 'sim') {
                    history.push("/cadastroEventoParticipanteHotelConvite/" + id);
                    return;
                }
                history.push("/cadastroEventoParticipanteConviteConcluido/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Transfer</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        {(dados.transferextra ?? "nao") === 'sim' ?
                                            <div className="card-body">
                                                <p><strong>Transfer extra: </strong>Transfer residência x aeroporto e/ou evento x residência</p>
                                                <p>Data e horário do transfer serão agendados conforme agenda do evento e informados na carta informativa.</p>
                                                {!['chiesi'].includes((dados.evento?.cliente?.modelo ?? "master")) ?
                                                    <>
                                                        <p>Por favor informe seu endereço:</p>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <textarea
                                                                        className="form-control form-control-lg"
                                                                        id="transferextraobservacao"
                                                                        placeholder="Endereço..."
                                                                        style={{ minHeight: "100px" }}
                                                                        value={dados.transferextraobservacao}
                                                                        onChange={e => setDados({ ...dados, ...{ "transferextraobservacao": e.target.value } })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null}
                                            </div> : null}
                                        {(dados.transfer ?? "nao") === 'sim' ?
                                            <div className="card-body">
                                                <p><strong>Transfer: </strong>Aeroporto x Hotel x Aeroporto</p>
                                                {!['chiesi'].includes((dados.evento?.cliente?.modelo ?? "master")) ?
                                                    <>
                                                        <p>Se você não for utilizar, ou tiver alguma dúvida sobre o serviço de transfer, por favor nos informe no campo abaixo:</p>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <textarea
                                                                        className="form-control form-control-lg"
                                                                        id="transferobservacao"
                                                                        placeholder="..."
                                                                        style={{ minHeight: "100px" }}
                                                                        value={dados.transferobservacao}
                                                                        onChange={e => setDados({ ...dados, ...{ "transferobservacao": e.target.value } })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null}
                                            </div> : null}
                                        <div className="card-body">
                                            <h4>Informações Importantes de Transfer</h4>
                                            <p>Ressaltamos que este serviço é exclusivo para o(a) médico(a) patrocinado(a), não estendido ao acompanhante. Este serviço será oferecido nos horários correspondentes a sua reserva aérea, desde que corresponda ao período do evento do qual temos relacionado em nosso banco de dados.</p>
                                            <p>Caso decida alterar o seu voo, o transfer será cancelado e o deslocamento ficará sob responsabilidade do(a) médico(a) patrocinado(a).<br />
                                                Em caso de alteração, atrasos e/ou cancelamentos de voos por parte da Cia. aérea, solicitamos a gentileza de entrar em contato conosco através do telefone (11)3777-8414, para que possamos providenciar os ajustes no transfer programado.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}