import React, { useEffect, useState } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaFileInvoice } from 'react-icons/fa';
import ReactSelect from '../../components/ReactSelect';
import api from '../../services/api';
import { data } from '../../util/util';

export default function RelatorioListaPresenca({ match }) {
    const idEvento = match.params.idEvento;
    const token = localStorage.getItem('token');
    const [filtro, setFiltro] = useState({
        modelorelatorio: '1',
        formatorelatorio: 'pdf',
    });

    const [dadosEvento, setDadosEvento] = useState({});
    const [camposQuestionario, setCamposQuestionario] = useState({});

    const buscar = () => {
        //busca dados do evento
        api({
            token: token,
            method: 'get',
            action: '/evento',
            params: {
                ...{ id: idEvento }
            }
        })
            .then(response => {
                var evento = response.data.data[0];

                if (filtro.datalista === undefined || filtro.datalista === null) {
                    if (evento.dataLista === undefined || evento.dataLista === null) {
                        //se nao tiver dataLista usa data inicial do evento
                        filtro.datalista = data(evento.data);
                        filtro.nomeDatalista = data(evento.data);
                    } else {
                        //se tiver dataLista usa dataLista
                        filtro.datalista = evento.dataLista;
                        filtro.nomeDatalista = evento.dataLista;
                    }
                }

                var camposQuestionarioRecebidos = evento.camposQuestionario ?
                    JSON.parse(evento.camposQuestionario) :
                    {
                        "tipoparticipante": "sim",
                        "nome": "sim",
                        "assinaturaListaPresenca": "sim",
                        "telefone": "sim",
                        "email": "sim",
                        "especialidade": "sim",
                        "rg": "sim",
                        "crm": "sim",
                        "crmuf": "sim",
                        "datanascimento": "sim",
                        "cpf": "sim",
                        "enderecologradouro": "sim",
                        "endereconumero": "sim",
                        "enderecocomplemento": "sim",
                        "enderecocep": "sim",
                        "enderecobairro": "sim",
                        "aereomunicipioembarque": "nao",
                        "enderecomunicipionome": "sim",
                        "enderecomunicipiouf": "sim",
                    };
                setCamposQuestionario(camposQuestionarioRecebidos);
                setDadosEvento(evento);
                setFiltro({ ...filtro });
            }).catch(err => {
                //console.log(err)
            });
    };//buscar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Exportar Lista de Presença</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/evento/exportarListaPresenca"}>
                                            <input type="hidden" name="token" value={token} />
                                            <input type="hidden" name="idEvento" value={idEvento} />
                                            <input type="hidden" name="camposQuestionario" value={JSON.stringify(camposQuestionario)} />
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Data</label>
                                                        <ReactSelect
                                                            id="datalista"
                                                            name="datalista"
                                                            value={{ value: filtro.datalista || null, label: filtro.nomeDatalista || null }}
                                                            onChange={(e) => {
                                                                filtro.datalista = e.value;
                                                                filtro.nomeDatalista = e.label;
                                                                setFiltro({ ...filtro });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    token: token,
                                                                    method: 'get',
                                                                    action: '/evento/datas/' + idEvento,
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro,
                                                                                label: registro
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo do Participante</label>
                                                        <ReactSelect
                                                            id="tipoparticipante"
                                                            name="tipoparticipante"
                                                            value={{ value: filtro.tipoparticipante, label: filtro.nomeTipoparticipante }}
                                                            onChange={(e) => {
                                                                filtro.tipoparticipante = e.value;
                                                                filtro.nomeTipoparticipante = e.label;
                                                                setFiltro({ ...filtro });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participanteTipo',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Formato</label>
                                                        <ReactSelect
                                                            name="formatorelatorio"
                                                            value={{ value: filtro.formatorelatorio, label: filtro.nomeFormatorelatorio }}
                                                            onChange={(e) => {
                                                                filtro.formatorelatorio = e.value;
                                                                filtro.nomeFormatorelatorio = e.label;
                                                                setFiltro({ ...filtro });
                                                            }}
                                                            options={[
                                                                { value: 'pdf', label: 'PDF' },
                                                                { value: 'excel', label: 'Excel' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                </div>
                                            </div>
                                            <h5>Campos do questionário</h5>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.nome}
                                                                    checked={camposQuestionario.nome === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.nome = (camposQuestionario.nome ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Nome</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.tipoparticipante}
                                                                    checked={camposQuestionario.tipoparticipante === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.tipoparticipante = (camposQuestionario.tipoparticipante ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Tipo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.idconvidado}
                                                                    checked={camposQuestionario.idconvidado === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.idconvidado = (camposQuestionario.idconvidado ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                ID</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.especialidade}
                                                                    checked={camposQuestionario.especialidade === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.especialidade = (camposQuestionario.especialidade ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Especialidade</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.crm}
                                                                    checked={camposQuestionario.crm === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.crm = (camposQuestionario.crm ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CRM</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.crmuf}
                                                                    checked={camposQuestionario.crmuf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.crmuf = (camposQuestionario.crmuf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Estado do CRM</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.instituicao}
                                                                    checked={camposQuestionario.instituicao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.instituicao = (camposQuestionario.instituicao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Instituição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.socio}
                                                                    checked={camposQuestionario.socio === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.socio = (camposQuestionario.socio ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Sócio</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.telefone}
                                                                    checked={camposQuestionario.telefone === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.telefone = (camposQuestionario.telefone ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Telefone</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.celular}
                                                                    checked={camposQuestionario.celular === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.celular = (camposQuestionario.celular ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Celular</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.email}
                                                                    checked={camposQuestionario.email === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.email = (camposQuestionario.email ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datanascimento}
                                                                    checked={camposQuestionario.datanascimento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datanascimento = (camposQuestionario.datanascimento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data de Nascimento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.cpf}
                                                                    checked={camposQuestionario.cpf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.cpf = (camposQuestionario.cpf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CPF</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.rg}
                                                                    checked={camposQuestionario.rg === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.rg = (camposQuestionario.rg ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                RG</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.passaporte}
                                                                    checked={camposQuestionario.passaporte === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.passaporte = (camposQuestionario.passaporte ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Passa Porte</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.passaportevencimento}
                                                                    checked={camposQuestionario.passaportevencimento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.passaportevencimento = (camposQuestionario.passaportevencimento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Vencimento Passaporte</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecologradouro}
                                                                    checked={camposQuestionario.enderecologradouro === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecologradouro = (camposQuestionario.enderecologradouro ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Endereço</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.endereconumero}
                                                                    checked={camposQuestionario.endereconumero === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.endereconumero = (camposQuestionario.endereconumero ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Número</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecomplemento}
                                                                    checked={camposQuestionario.enderecomplemento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecomplemento = (camposQuestionario.enderecomplemento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Complemento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecocep}
                                                                    checked={camposQuestionario.enderecocep === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecocep = (camposQuestionario.enderecocep ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CEP</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecobairro}
                                                                    checked={camposQuestionario.enderecobairro === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecobairro = (camposQuestionario.enderecobairro ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Bairro</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecomunicipionome}
                                                                    checked={camposQuestionario.enderecomunicipionome === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecomunicipionome = (camposQuestionario.enderecomunicipionome ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Cidade</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecomunicipiouf}
                                                                    checked={camposQuestionario.enderecomunicipiouf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecomunicipiouf = (camposQuestionario.enderecomunicipiouf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Estado</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.produto}
                                                                    checked={camposQuestionario.produto === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.produto = (camposQuestionario.produto ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Produto</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.ordeminterna}
                                                                    checked={camposQuestionario.ordeminterna === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.ordeminterna = (camposQuestionario.ordeminterna ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Ordem Interna</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentecomercial}
                                                                    checked={camposQuestionario.gerentecomercial === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentecomercial = (camposQuestionario.gerentecomercial ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Gerente Comercial</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentedistrital}
                                                                    checked={camposQuestionario.gerentedistrital === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentedistrital = (camposQuestionario.gerentedistrital ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Gerente Distrital</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentedistritalemail}
                                                                    checked={camposQuestionario.gerentedistritalemail === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentedistritalemail = (camposQuestionario.gerentedistritalemail ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail Ger. Distr.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representante}
                                                                    checked={camposQuestionario.representante === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representante = (camposQuestionario.representante ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representanteemail}
                                                                    checked={camposQuestionario.representanteemail === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representanteemail = (camposQuestionario.representanteemail ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representantetelefone}
                                                                    checked={camposQuestionario.representantetelefone === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representantetelefone = (camposQuestionario.representantetelefone ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Telefone Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.vinculoOrgaoPublico}
                                                                    checked={camposQuestionario.vinculoOrgaoPublico === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.vinculoOrgaoPublico = (camposQuestionario.vinculoOrgaoPublico ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Vínculo Orgão Público</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.assinaturaCartaConvite}
                                                                    checked={camposQuestionario.assinaturaCartaConvite === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.assinaturaCartaConvite = (camposQuestionario.assinaturaCartaConvite ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Assinatura Carta Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.inscricao}
                                                                    checked={camposQuestionario.inscricao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.inscricao = (camposQuestionario.inscricao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Inscrição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.hotelhospedagem}
                                                                    checked={camposQuestionario.hotelhospedagem === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.hotelhospedagem = (camposQuestionario.hotelhospedagem ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Hospedagem</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aereo}
                                                                    checked={camposQuestionario.aereo === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aereo = (camposQuestionario.aereo ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Aéreo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.bagagemDespachada}
                                                                    checked={camposQuestionario.bagagemDespachada === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.bagagemDespachada = (camposQuestionario.bagagemDespachada ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Bagagem Despachada</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aereomunicipioembarque}
                                                                    checked={camposQuestionario.aereomunicipioembarque === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aereomunicipioembarque = (camposQuestionario.aereomunicipioembarque ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Cidade Embarque</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.transfer}
                                                                    checked={camposQuestionario.transfer === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.transfer = (camposQuestionario.transfer ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Transfer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.transferextra}
                                                                    checked={camposQuestionario.transferextra === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.transferextra = (camposQuestionario.transferextra ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Transfer Extra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.situacaoevento}
                                                                    checked={camposQuestionario.situacaoevento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.situacaoevento = (camposQuestionario.situacaoevento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Status</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.observacao}
                                                                    checked={camposQuestionario.observacao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.observacao = (camposQuestionario.observacao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Observação</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.presentenoevento}
                                                                    checked={camposQuestionario.presentenoevento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.presentenoevento = (camposQuestionario.presentenoevento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Presente no evento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.inscricaonumero}
                                                                    checked={camposQuestionario.inscricaonumero === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.inscricaonumero = (camposQuestionario.inscricaonumero ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Número Inscrição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.dataopcaoaereo}
                                                                    checked={camposQuestionario.dataopcaoaereo === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.dataopcaoaereo = (camposQuestionario.dataopcaoaereo ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Opção Aéreo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartaconvite}
                                                                    checked={camposQuestionario.datacartaconvite === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartaconvite = (camposQuestionario.datacartaconvite ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Carta Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartaassinada}
                                                                    checked={camposQuestionario.datacartaassinada === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartaassinada = (camposQuestionario.datacartaassinada ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Aceite Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartainformativa}
                                                                    checked={camposQuestionario.datacartainformativa === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartainformativa = (camposQuestionario.datacartainformativa ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Carta Informativa</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.dataconfirmacaocartainformativa}
                                                                    checked={camposQuestionario.dataconfirmacaocartainformativa === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.dataconfirmacaocartainformativa = (camposQuestionario.dataconfirmacaocartainformativa ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Confirmação Carta Informativa</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna1}
                                                                    checked={camposQuestionario.coluna1 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna1 = (camposQuestionario.coluna1 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna1 ?? "Coluna 1"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna2}
                                                                    checked={camposQuestionario.coluna2 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna2 = (camposQuestionario.coluna2 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna2 ?? "Coluna 2"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna3}
                                                                    checked={camposQuestionario.coluna3 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna3 = (camposQuestionario.coluna3 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna3 ?? "Coluna 3"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna4}
                                                                    checked={camposQuestionario.coluna4 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna4 = (camposQuestionario.coluna4 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna4 ?? "Coluna 4"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna5}
                                                                    checked={camposQuestionario.coluna5 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna5 = (camposQuestionario.coluna5 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna5 ?? "Coluna 5"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna6}
                                                                    checked={camposQuestionario.coluna6 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna6 = (camposQuestionario.coluna6 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna6 ?? "Coluna 6"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna7}
                                                                    checked={camposQuestionario.coluna7 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna7 = (camposQuestionario.coluna7 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna7 ?? "Coluna 7"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna8}
                                                                    checked={camposQuestionario.coluna8 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna8 = (camposQuestionario.coluna8 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna8 ?? "Coluna 8"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna9}
                                                                    checked={camposQuestionario.coluna9 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna9 = (camposQuestionario.coluna9 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna9 ?? "Coluna 9"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna10}
                                                                    checked={camposQuestionario.coluna10 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna10 = (camposQuestionario.coluna10 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dadosEvento.nomecoluna10 ?? "Coluna 10"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aceitetermoassinatura}
                                                                    checked={camposQuestionario.aceitetermoassinatura === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aceitetermoassinatura = (camposQuestionario.aceitetermoassinatura ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Aceite Termo Assinatura</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.assinaturaListaPresenca}
                                                                    checked={camposQuestionario.assinaturaListaPresenca === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.assinaturaListaPresenca = (camposQuestionario.assinaturaListaPresenca ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Assinatura Lista Presença</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaFileInvoice size={20} /> Exportar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
