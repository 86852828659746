import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaPlane, FaSave, FaUsers, FaUpload } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import NavItem from '../../components/NavItem';
import { QRCodeCanvas } from 'qrcode.react';
import { ChromePicker } from 'react-color';

export default function CadastroEventoRsvp({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");

    const [dados, setDados] = useState({
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    const [camposQuestionario, setCamposQuestionario] = useState({});

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'diretorresponsavel',
                        'cliente',
                        'empresa',
                        'municipio',
                        'pais',
                        'modelotermoaceite',
                        'modelocontrato',
                        'modeloemailconvite',
                        'modelocartaconvite',
                        'modelocarta',
                        'coordenadorrsvp',
                    ]
                });
                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);
                dadosRecebidos.dataFim = data(dadosRecebidos.dataFim);
                dadosRecebidos.dataLimiteAceite = data(dadosRecebidos.dataLimiteAceite);

                if (dadosRecebidos.responsavel?.constructor !== Array)
                    dadosRecebidos.responsavel = [];
                dadosRecebidos.responsavel = dadosRecebidos.responsavel.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.responsavelcliente?.constructor !== Array)
                    dadosRecebidos.responsavelcliente = [];
                dadosRecebidos.responsavelcliente = dadosRecebidos.responsavelcliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                var camposQuestionarioRecebidos = dadosRecebidos.camposQuestionario ?
                    JSON.parse(dadosRecebidos.camposQuestionario) :
                    {
                        "tipoparticipante": "sim",
                        "nome": "sim",
                        "assinaturaListaPresenca": "sim",
                        "telefone": "sim",
                        "email": "sim",
                        "especialidade": "sim",
                        "rg": "sim",
                        "crm": "sim",
                        "crmuf": "sim",
                        "datanascimento": "sim",
                        "cpf": "sim",
                        "enderecologradouro": "sim",
                        "endereconumero": "sim",
                        "enderecocomplemento": "sim",
                        "enderecocep": "sim",
                        "enderecobairro": "sim",
                        "aereomunicipioembarque": "sim",
                        "enderecomunicipionome": "sim",
                        "enderecomunicipiouf": "sim",
                    };
                setCamposQuestionario(camposQuestionarioRecebidos);
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {

            setCamposQuestionario({
                "tipoparticipante": "sim",
                "nome": "sim",
                "assinaturaListaPresenca": "sim",
                "telefone": "sim",
                "email": "sim",
                "especialidade": "sim",
                "rg": "sim",
                "crm": "sim",
                "crmuf": "sim",
                "datanascimento": "sim",
                "cpf": "sim",
                "enderecologradouro": "sim",
                "endereconumero": "sim",
                "enderecocomplemento": "sim",
                "enderecocep": "sim",
                "enderecobairro": "sim",
                "aereomunicipioembarque": "sim",
                "enderecomunicipionome": "sim",
                "enderecomunicipiouf": "sim",
            });

            setDados({
                ...dados,
                ...{
                    pais: '30',
                    nomePais: 'Brasil'
                }
            });
        }
    };

    const validar = () => {
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o Nome do evento');
            setTimeout(() => {
                nome.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        var cliente = document.querySelector("#cliente");
        if (dados.cliente === undefined || dados.cliente === '') {
            toast.error('É necessário preencher o Cliente');
            setTimeout(() => {
                cliente.focus();
            }, 500);
            setTabState("geral");
            return false;
        }

        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }
        if (dados.dataFim) {
            dados.dataFim = dataMysql(dados.dataFim);
        }
        if (dados.dataLimiteAceite) {
            dados.dataLimiteAceite = dataMysql(dados.dataLimiteAceite);
        }

        if (dados.responsavel?.constructor === Array) {
            dados.responsavel = dados.responsavel.map(item => item.value).join(',');
        }

        if (dados.responsavelcliente?.constructor === Array) {
            dados.responsavelcliente = dados.responsavelcliente.map(item => item.value).join(',');
        }

        dados.camposQuestionario = JSON.stringify(camposQuestionario);

        if (id) {//update
            api({
                method: 'put',
                action: '/evento/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/evento?idEvento=" + id);
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert

            dados.rsvp = 'sim'; // se for criado nessa tela sempre tem rsvp ativado

            api({
                method: 'post',
                action: '/evento',
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/evento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to={"/cadastroEventoRsvpOpcaoAereo/" + id} icon={<FaPlane size={20} />} color="outline-dark">Opções de Aéreo</NavItem>
                    <NavItem to={"/eventoImportarOpcaoAereo/" + id} icon={<FaUpload size={20} />} color="outline-dark">Importar Opções de Aéreo</NavItem>
                    <NavItem to={"/eventoParticipante/" + id} icon={<FaUsers size={20} />} color="outline-dark">Participantes</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Dados RSVP</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <h4>Geral</h4>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            value={{ value: dados.cliente, label: dados.nomeCliente }}
                                                            onChange={e => setDados({ ...dados, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastroUnico: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="identificacao" placeholder="Identificação"
                                                            value={dados.identificacao}
                                                            onChange={e => setDados({ ...dados, ...{ "identificacao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Identificação</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Evento</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomersvp" placeholder="Nome do Evento para o Rsvp"
                                                            value={dados.nomersvp}
                                                            onChange={e => setDados({ ...dados, ...{ "nomersvp": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Evento para o Rsvp</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Formato</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipoRealizacao, label: dados.nomeTipoRealizacao }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipoRealizacao": e.value, "nomeTipoRealizacao": e.label } })}
                                                            options={[
                                                                { value: 'digital', label: 'Digital' },
                                                                { value: 'presencial', label: 'Presencial' },
                                                                { value: 'hibrido', label: 'Híbrido' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Nacional ou Internacional</label>
                                                        <ReactSelect
                                                            id="nacionalidade"
                                                            value={{ value: dados.nacionalidade, label: dados.nomeNacionalidade }}
                                                            onChange={e => setDados({ ...dados, ...{ "nacionalidade": e.value, "nomeNacionalidade": e.label } })}
                                                            options={[
                                                                { value: 'nacional', label: 'Nacional' },
                                                                { value: 'internacional', label: 'Internacional' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>E-mail Convite</label>
                                                        <ReactSelect
                                                            id="empresa"
                                                            value={{ value: dados.modeloemailconvite, label: dados.nomeModeloemailconvite }}
                                                            onChange={e => setDados({ ...dados, ...{ "modeloemailconvite": e.value, "nomeModeloemailconvite": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoCarta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "emailconvite",
                                                                        eventoTipo: dados.tipo || null,
                                                                        eventoTipoRealizacao: dados.tipoRealizacao || null,
                                                                        eventoNacionalidade: dados.nacionalidade || null,
                                                                        cliente: dados.cliente?.id || null,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro.id,
                                                                                label: registro.codigo + " - " + registro.nome,
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Contrato</label>
                                                        <ReactSelect
                                                            id="empresa"
                                                            value={{ value: dados.modelocontrato, label: dados.nomeModelocontrato }}
                                                            onChange={e => setDados({ ...dados, ...{ "modelocontrato": e.value, "nomeModelocontrato": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoCarta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "contrato",
                                                                        eventoTipo: dados.tipo || null,
                                                                        eventoTipoRealizacao: dados.tipoRealizacao || null,
                                                                        eventoNacionalidade: dados.nacionalidade || null,
                                                                        cliente: dados.cliente?.id || null,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro.id,
                                                                                label: registro.codigo + " - " + registro.nome,
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Termo de Aceite</label>
                                                        <ReactSelect
                                                            id="empresa"
                                                            value={{ value: dados.modelotermoaceite, label: dados.nomeModelotermoaceite }}
                                                            onChange={e => setDados({ ...dados, ...{ "modelotermoaceite": e.value, "nomeModelotermoaceite": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoCarta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "termoaceite",
                                                                        eventoTipo: dados.tipo || null,
                                                                        eventoTipoRealizacao: dados.tipoRealizacao || null,
                                                                        eventoNacionalidade: dados.nacionalidade || null,
                                                                        cliente: dados.cliente?.id || null,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro.id,
                                                                                label: registro.codigo + " - " + registro.nome,
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Carta Convite</label>
                                                        <ReactSelect
                                                            id="empresa"
                                                            value={{ value: dados.modelocartaconvite, label: dados.nomeModelocartaconvite }}
                                                            onChange={e => setDados({ ...dados, ...{ "modelocartaconvite": e.value, "nomeModelocartaconvite": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoCarta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "convite",
                                                                        eventoTipo: dados.tipo || null,
                                                                        eventoTipoRealizacao: dados.tipoRealizacao || null,
                                                                        eventoNacionalidade: dados.nacionalidade || null,
                                                                        cliente: dados.cliente?.id || null,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro.id,
                                                                                label: registro.codigo + " - " + registro.nome,
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Carta Informativa</label>
                                                        <ReactSelect
                                                            id="empresa"
                                                            value={{ value: dados.modelocarta, label: dados.nomeModelocarta }}
                                                            onChange={e => setDados({ ...dados, ...{ "modelocarta": e.value, "nomeModelocarta": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/eventoCarta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "informativa",
                                                                        eventoTipo: dados.tipo || null,
                                                                        eventoTipoRealizacao: dados.tipoRealizacao || null,
                                                                        eventoNacionalidade: dados.nacionalidade || null,
                                                                        cliente: dados.cliente?.id || null,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return {
                                                                                value: registro.id,
                                                                                label: registro.codigo + " - " + registro.nome,
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="emailremetente" placeholder="E-mail remetente do evento"
                                                            value={dados.emailremetente}
                                                            onChange={e => setDados({ ...dados, ...{ "emailremetente": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">E-mail remetente do evento</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="emailbackup" placeholder="E-mail de backup"
                                                            value={dados.emailbackup}
                                                            onChange={e => setDados({ ...dados, ...{ "emailbackup": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">E-mail de backup</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h4>Responsáveis</h4>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Responsável pelo RSVP</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.coordenadorrsvp, label: dados.nomeCoordenadorrsvp }}
                                                                    onChange={e => setDados({ ...dados, ...{ "coordenadorrsvp": e.value, "nomeCoordenadorrsvp": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "pessoa",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefonecoordenadorrsvp" placeholder="Telefone do Responsável pelo RSVP"
                                                                    value={dados.telefonecoordenadorrsvp}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefonecoordenadorrsvp": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone do Responsável pelo RSVP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coordenadorevento" placeholder="João"
                                                                    value={dados.coordenadorevento}
                                                                    onChange={e => setDados({ ...dados, ...{ "coordenadorevento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Coordenador do Evento</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefonecoordenadorevento" placeholder="Texto Data"
                                                                    value={dados.telefonecoordenadorevento}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefonecoordenadorevento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone do Coordenador do Evento</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Responsável na Agência</label>
                                                                <ReactSelect
                                                                    isMulti={true}
                                                                    value={dados.responsavel ?? []}
                                                                    onChange={(e) => {
                                                                        dados.responsavel = dados.responsavel || [];
                                                                        dados.responsavel = e;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "pessoa",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Diretor Responsável</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.diretorresponsavel, label: dados.nomeDiretorresponsavel }}
                                                                    onChange={e => setDados({ ...dados, ...{ "diretorresponsavel": e.value, "nomeDiretorresponsavel": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "pessoa",
                                                                                perfil: [1],
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Responsável no Cliente (Focal Point Eventos)</label>
                                                                <ReactSelect
                                                                    isMulti={true}
                                                                    value={dados.responsavelcliente ?? []}
                                                                    onChange={(e) => {
                                                                        dados.responsavelcliente = dados.responsavelcliente || [];
                                                                        dados.responsavelcliente = e;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/eventoResponsavelCliente',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: registro.nome
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h4>Datas</h4>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"data"}
                                                                label="Data Inicial"
                                                                value={dados.data}
                                                                onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hora" placeholder="Horário Inicial"
                                                                    value={dados.hora}
                                                                    onChange={e => setDados({ ...dados, ...{ "hora": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Inicial</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <Data
                                                                label="Data Final"
                                                                value={dados.dataFim}
                                                                onChange={date => setDados({ ...dados, ...{ "dataFim": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="horaFim" placeholder="Horário Final"
                                                                    value={dados.horaFim}
                                                                    onChange={e => setDados({ ...dados, ...{ "horaFim": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Final</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-9">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="textodata" placeholder="Telefone do Coordenador do Evento"
                                                                    value={dados.textodata}
                                                                    onChange={e => setDados({ ...dados, ...{ "textodata": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Texto Data</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"dataLimiteAceite"}
                                                                label="Data Limite Aceite"
                                                                value={dados.dataLimiteAceite}
                                                                onChange={date => setDados({ ...dados, ...{ "dataLimiteAceite": date } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="agendaprevia"
                                                                    placeholder="Agenda Prévia"
                                                                    style={{ minHeight: "150px" }}
                                                                    value={dados.agendaprevia}
                                                                    onChange={e => setDados({ ...dados, ...{ "agendaprevia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Agenda Prévia</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Local</h4>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="local" placeholder="Local"
                                                            value={dados.local}
                                                            onChange={e => setDados({ ...dados, ...{ "local": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Local</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="endereco" placeholder="Endereço"
                                                            value={dados.endereco}
                                                            onChange={e => setDados({ ...dados, ...{ "endereco": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Endereço</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Cidade</label>
                                                        <ReactSelect
                                                            value={{ value: dados.municipio, label: dados.nomeMunicipio }}
                                                            onChange={e => setDados({ ...dados, ...{ "municipio": e.value, "nomeMunicipio": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/municipio',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="municipioExterior" placeholder="Município Exterior"
                                                            value={dados.municipioExterior}
                                                            onChange={e => setDados({ ...dados, ...{ "municipioExterior": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Município Exterior</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="bairro" placeholder="Bairro"
                                                            value={dados.bairro}
                                                            onChange={e => setDados({ ...dados, ...{ "bairro": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Bairro</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>País</label>
                                                        <ReactSelect
                                                            value={{ value: dados.pais, label: dados.nomePais }}
                                                            onChange={e => setDados({ ...dados, ...{ "pais": e.value, "nomePais": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/pais',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Colunas Nomeáveis</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna1" placeholder="Nome Coluna 1"
                                                            value={dados.nomecoluna1}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna1": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 1</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna2" placeholder="Nome Coluna 2"
                                                            value={dados.nomecoluna2}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna2": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 2</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna3" placeholder="Nome Coluna 3"
                                                            value={dados.nomecoluna3}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna3": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 3</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna4" placeholder="Nome Coluna 4"
                                                            value={dados.nomecoluna4}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna4": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 4</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna5" placeholder="Nome Coluna 5"
                                                            value={dados.nomecoluna5}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna5": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 5</label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna6" placeholder="Nome Coluna 6"
                                                            value={dados.nomecoluna6}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna6": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 6</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna7" placeholder="Nome Coluna 7"
                                                            value={dados.nomecoluna7}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna7": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 7</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna8" placeholder="Nome Coluna 8"
                                                            value={dados.nomecoluna8}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna8": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 8</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna9" placeholder="Nome Coluna 9"
                                                            value={dados.nomecoluna9}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna9": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 9</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomecoluna10" placeholder="Nome Coluna 10"
                                                            value={dados.nomecoluna10}
                                                            onChange={e => setDados({ ...dados, ...{ "nomecoluna10": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Coluna 10</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Personalizar cadastro</h4>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="linkfinalcadastro" placeholder="Link no final do cadastro"
                                                                    value={dados.linkfinalcadastro}
                                                                    onChange={e => setDados({ ...dados, ...{ "linkfinalcadastro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Link no final do cadastro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="file" multiple={false} className="form-control form-control-lg" id="arquivoagenda" placeholder="Arquivo Agenda (.pdf)"
                                                                    value={dados.nomeArquivoagenda}
                                                                    onChange={(e) => {
                                                                        var nomeArquivoagenda = e.target.value;
                                                                        //transforma binario em json hexadecimal
                                                                        const fileReader = new FileReader();
                                                                        fileReader.readAsBinaryString(e.target.files[0]);
                                                                        fileReader.onload = e => {
                                                                            setDados({
                                                                                ...dados,
                                                                                ...{
                                                                                    "nomeArquivoagenda": nomeArquivoagenda,
                                                                                    "binarioArquivoagenda": bin2hex(e.target.result),
                                                                                }
                                                                            });
                                                                        };
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Arquivo Agenda (.pdf)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="logomarca" placeholder="Logomarca (.jpeg)"
                                                                            value={dados.nomeLogomarca}
                                                                            onChange={(e) => {
                                                                                var nomeLogomarca = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeLogomarca": nomeLogomarca,
                                                                                            "binarioLogomarca": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Logomarca (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.logomarca?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.logomarca.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="imagemfundo" placeholder="Imagem de Fundo (.jpeg)"
                                                                            value={dados.nomeImagemfundo}
                                                                            onChange={(e) => {
                                                                                var nomeImagemfundo = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeImagemfundo": nomeImagemfundo,
                                                                                            "binarioImagemfundo": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Imagem de Fundo (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.imagemfundo?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.imagemfundo.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="floatingInput">QRCode para cadastro no evento</label>
                                                                <br />
                                                                <QRCodeCanvas value={"https://sistema.infoaccess-mkt.com.br/loginEvento/" + id} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input readOnly={true} type="text" className="form-control form-control-lg" id="linkCadastroEvento" placeholder="Link para cadastro no evento"
                                                                    value={"https://sistema.infoaccess-mkt.com.br/loginEvento/" + id}
                                                                />
                                                                <label htmlFor="floatingInput">Link para cadastro no evento</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label htmlFor="floatingInput">Cor de fudo</label>
                                                            <ChromePicker
                                                                color={dados.cor ?? "#FFFFFF"}
                                                                onChange={(color, event) => {
                                                                    dados.cor = color.hex;
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h5>Campos do questionário</h5>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.nome}
                                                                    checked={camposQuestionario.nome === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.nome = (camposQuestionario.nome ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Nome</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.tipoparticipante}
                                                                    checked={camposQuestionario.tipoparticipante === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.tipoparticipante = (camposQuestionario.tipoparticipante ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Tipo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.idconvidado}
                                                                    checked={camposQuestionario.idconvidado === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.idconvidado = (camposQuestionario.idconvidado ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                ID</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.especialidade}
                                                                    checked={camposQuestionario.especialidade === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.especialidade = (camposQuestionario.especialidade ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Especialidade</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.crm}
                                                                    checked={camposQuestionario.crm === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.crm = (camposQuestionario.crm ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CRM</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.crmuf}
                                                                    checked={camposQuestionario.crmuf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.crmuf = (camposQuestionario.crmuf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Estado do CRM</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.instituicao}
                                                                    checked={camposQuestionario.instituicao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.instituicao = (camposQuestionario.instituicao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Instituição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.socio}
                                                                    checked={camposQuestionario.socio === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.socio = (camposQuestionario.socio ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Sócio</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.telefone}
                                                                    checked={camposQuestionario.telefone === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.telefone = (camposQuestionario.telefone ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Telefone</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.celular}
                                                                    checked={camposQuestionario.celular === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.celular = (camposQuestionario.celular ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Celular</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.email}
                                                                    checked={camposQuestionario.email === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.email = (camposQuestionario.email ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datanascimento}
                                                                    checked={camposQuestionario.datanascimento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datanascimento = (camposQuestionario.datanascimento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data de Nascimento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.cpf}
                                                                    checked={camposQuestionario.cpf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.cpf = (camposQuestionario.cpf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CPF</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.rg}
                                                                    checked={camposQuestionario.rg === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.rg = (camposQuestionario.rg ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                RG</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.passaporte}
                                                                    checked={camposQuestionario.passaporte === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.passaporte = (camposQuestionario.passaporte ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Passa Porte</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.passaportevencimento}
                                                                    checked={camposQuestionario.passaportevencimento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.passaportevencimento = (camposQuestionario.passaportevencimento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Vencimento Passaporte</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecologradouro}
                                                                    checked={camposQuestionario.enderecologradouro === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecologradouro = (camposQuestionario.enderecologradouro ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Endereço</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.endereconumero}
                                                                    checked={camposQuestionario.endereconumero === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.endereconumero = (camposQuestionario.endereconumero ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Número</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecocomplemento}
                                                                    checked={camposQuestionario.enderecocomplemento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecocomplemento = (camposQuestionario.enderecocomplemento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Complemento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecocep}
                                                                    checked={camposQuestionario.enderecocep === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecocep = (camposQuestionario.enderecocep ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                CEP</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecobairro}
                                                                    checked={camposQuestionario.enderecobairro === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecobairro = (camposQuestionario.enderecobairro ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Bairro</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecomunicipionome}
                                                                    checked={camposQuestionario.enderecomunicipionome === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecomunicipionome = (camposQuestionario.enderecomunicipionome ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Cidade</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.enderecomunicipiouf}
                                                                    checked={camposQuestionario.enderecomunicipiouf === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.enderecomunicipiouf = (camposQuestionario.enderecomunicipiouf ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Estado</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.produto}
                                                                    checked={camposQuestionario.produto === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.produto = (camposQuestionario.produto ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Produto</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.ordeminterna}
                                                                    checked={camposQuestionario.ordeminterna === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.ordeminterna = (camposQuestionario.ordeminterna ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Ordem Interna</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentecomercial}
                                                                    checked={camposQuestionario.gerentecomercial === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentecomercial = (camposQuestionario.gerentecomercial ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Gerente Comercial</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentedistrital}
                                                                    checked={camposQuestionario.gerentedistrital === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentedistrital = (camposQuestionario.gerentedistrital ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Gerente Distrital</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.gerentedistritalemail}
                                                                    checked={camposQuestionario.gerentedistritalemail === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.gerentedistritalemail = (camposQuestionario.gerentedistritalemail ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail Ger. Distr.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representante}
                                                                    checked={camposQuestionario.representante === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representante = (camposQuestionario.representante ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representanteemail}
                                                                    checked={camposQuestionario.representanteemail === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representanteemail = (camposQuestionario.representanteemail ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                E-mail Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.representantetelefone}
                                                                    checked={camposQuestionario.representantetelefone === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.representantetelefone = (camposQuestionario.representantetelefone ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Telefone Representante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.vinculoOrgaoPublico}
                                                                    checked={camposQuestionario.vinculoOrgaoPublico === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.vinculoOrgaoPublico = (camposQuestionario.vinculoOrgaoPublico ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Vínculo Órgão Público</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.assinaturaCartaConvite}
                                                                    checked={camposQuestionario.assinaturaCartaConvite === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.assinaturaCartaConvite = (camposQuestionario.assinaturaCartaConvite ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Assinatura Carta Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.inscricao}
                                                                    checked={camposQuestionario.inscricao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.inscricao = (camposQuestionario.inscricao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Inscrição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.hotelhospedagem}
                                                                    checked={camposQuestionario.hotelhospedagem === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.hotelhospedagem = (camposQuestionario.hotelhospedagem ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Hospedagem</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aereo}
                                                                    checked={camposQuestionario.aereo === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aereo = (camposQuestionario.aereo ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Aéreo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.bagagemDespachada}
                                                                    checked={camposQuestionario.bagagemDespachada === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.bagagemDespachada = (camposQuestionario.bagagemDespachada ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Bagagem Despachada</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aereomunicipioembarque}
                                                                    checked={camposQuestionario.aereomunicipioembarque === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aereomunicipioembarque = (camposQuestionario.aereomunicipioembarque ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Cidade Embarque</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.transfer}
                                                                    checked={camposQuestionario.transfer === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.transfer = (camposQuestionario.transfer ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Transfer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.transferextra}
                                                                    checked={camposQuestionario.transferextra === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.transferextra = (camposQuestionario.transferextra ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Transfer Extra</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.situacaoevento}
                                                                    checked={camposQuestionario.situacaoevento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.situacaoevento = (camposQuestionario.situacaoevento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Status</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.observacao}
                                                                    checked={camposQuestionario.observacao === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.observacao = (camposQuestionario.observacao ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Observação</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.presentenoevento}
                                                                    checked={camposQuestionario.presentenoevento === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.presentenoevento = (camposQuestionario.presentenoevento ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Presente no evento</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.inscricaonumero}
                                                                    checked={camposQuestionario.inscricaonumero === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.inscricaonumero = (camposQuestionario.inscricaonumero ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Número Inscrição</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.dataopcaoaereo}
                                                                    checked={camposQuestionario.dataopcaoaereo === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.dataopcaoaereo = (camposQuestionario.dataopcaoaereo ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Opção Aéreo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartaconvite}
                                                                    checked={camposQuestionario.datacartaconvite === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartaconvite = (camposQuestionario.datacartaconvite ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Carta Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartaassinada}
                                                                    checked={camposQuestionario.datacartaassinada === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartaassinada = (camposQuestionario.datacartaassinada ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Aceite Convite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.datacartainformativa}
                                                                    checked={camposQuestionario.datacartainformativa === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.datacartainformativa = (camposQuestionario.datacartainformativa ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Carta Informativa</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.dataconfirmacaocartainformativa}
                                                                    checked={camposQuestionario.dataconfirmacaocartainformativa === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.dataconfirmacaocartainformativa = (camposQuestionario.dataconfirmacaocartainformativa ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Data Confirmação Carta Informativa</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna1}
                                                                    checked={camposQuestionario.coluna1 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna1 = (camposQuestionario.coluna1 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna1 ?? "Coluna 1"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna2}
                                                                    checked={camposQuestionario.coluna2 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna2 = (camposQuestionario.coluna2 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna2 ?? "Coluna 2"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna3}
                                                                    checked={camposQuestionario.coluna3 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna3 = (camposQuestionario.coluna3 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna3 ?? "Coluna 3"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna4}
                                                                    checked={camposQuestionario.coluna4 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna4 = (camposQuestionario.coluna4 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna4 ?? "Coluna 4"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna5}
                                                                    checked={camposQuestionario.coluna5 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna5 = (camposQuestionario.coluna5 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna5 ?? "Coluna 5"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna6}
                                                                    checked={camposQuestionario.coluna6 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna6 = (camposQuestionario.coluna6 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna6 ?? "Coluna 6"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna7}
                                                                    checked={camposQuestionario.coluna7 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna7 = (camposQuestionario.coluna7 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna7 ?? "Coluna 7"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna8}
                                                                    checked={camposQuestionario.coluna8 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna8 = (camposQuestionario.coluna8 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna8 ?? "Coluna 8"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna9}
                                                                    checked={camposQuestionario.coluna9 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna9 = (camposQuestionario.coluna9 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna9 ?? "Coluna 9"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.coluna10}
                                                                    checked={camposQuestionario.coluna10 === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.coluna10 = (camposQuestionario.coluna10 ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                {dados.nomecoluna10 ?? "Coluna 10"}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.aceitetermoassinatura}
                                                                    checked={camposQuestionario.aceitetermoassinatura === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.aceitetermoassinatura = (camposQuestionario.aceitetermoassinatura ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Aceite Termo Assinatura</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={camposQuestionario.assinaturaListaPresenca}
                                                                    checked={camposQuestionario.assinaturaListaPresenca === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        camposQuestionario.assinaturaListaPresenca = (camposQuestionario.assinaturaListaPresenca ?? "nao") === "nao" ? "sim" : "nao";
                                                                        setCamposQuestionario({ ...camposQuestionario });
                                                                    }} />
                                                                Assinatura Lista Presença</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4>Termo de Aceite da Assinatura</h4>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <textarea
                                                            className="form-control form-control-lg"
                                                            id="termoaceiteassinatura"
                                                            placeholder="Termo de Aceite"
                                                            style={{ minHeight: "400px" }}
                                                            value={dados.termoaceiteassinatura}
                                                            onChange={e => setDados({ ...dados, ...{ "termoaceiteassinatura": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Termo de Aceite</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row fixoRodape">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate>
    );
}